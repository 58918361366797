export const dummymanimage = require("./../../assets/images/manimage.jpeg")

export const assessmentsresults = [
  { skill: 'React JS', score: 20 },
  { skill: 'Python', score: 40 },
  { skill: 'JavaScript', score: 35 },
  { skill: 'Node.js', score: 50 },
  { skill: 'Java', score: 30 },
  { skill: 'C++', score: 45 },
];

export const schoolsAttended = [
  {
    school: "University of Nairobi",
    program: "Computer Science",
    degree: "Bachelor's Degree",
    startdate: "2010-09-01",
    enddate: "2014-06-30",
    description: "Studied core concepts in software engineering, databases, and network systems."
  },
  {
    school: "Jomo Kenyatta University of Agriculture and Technology (JKUAT)",
    program: "Software Engineering",
    degree: "Master's Degree",
    startdate: "2015-09-01",
    enddate: "2017-06-30",
    description: "Specialized in software development methodologies and project management."
  }
];

export const assessmentsdummy = [
  {
    id: 1,
    name: 'Angular (Intermediate)',
    description: 'Test your Angular knowledge at an intermediate level, covering services, routing, forms, and more.',
    quizes: 16,
    solved: 2,
    attempts: 4,
    status: 'Progress',
    created_at: '2024-01-15T08:30:00.000000Z'
  },
  {
    id: 2,
    name: 'React (Beginner)',
    description: 'A beginner-friendly assessment for those starting with React fundamentals, JSX, and components.',
    quizes: 10,
    solved: 6,
    attempts: 2,
    status: 'Completed',
    created_at: '2024-02-10T09:00:00.000000Z'
  },
  {
    id: 3,
    name: 'Node.js (Advanced)',
    description: 'Test your Node.js expertise including streams, buffers, and event-driven architecture.',
    quizes: 20,
    solved: 10,
    attempts: 6,
    status: 'In Progress',
    created_at: '2024-03-05T11:15:00.000000Z'
  },
  {
    id: 4,
    name: 'Vue.js (Intermediate)',
    description: 'An intermediate-level test for Vue.js covering state management, directives, and components.',
    quizes: 12,
    solved: 5,
    attempts: 3,
    status: 'Not Started',
    created_at: '2024-04-01T12:30:00.000000Z'
  },
  {
    id: 5,
    name: 'Python (Advanced)',
    description: 'Assess your advanced knowledge of Python, including generators, decorators, and multithreading.',
    quizes: 25,
    solved: 20,
    attempts: 5,
    status: 'Completed',
    created_at: '2024-05-20T14:45:00.000000Z'
  },
  {
    id: 6,
    name: 'JavaScript (Intermediate)',
    description: 'Test your skills on JavaScript, including ES6+ features, closures, and asynchronous programming.',
    quizes: 15,
    solved: 7,
    attempts: 4,
    status: 'In Progress',
    created_at: '2024-06-15T10:00:00.000000Z'
  }
];




export const systemassessments = [
  {
    id: 1,
    name: 'Angular',
    description: 'Test your Angular knowledge at an intermediate level, covering services, routing, forms, and more.',
    duration: "1 hr 30 mins",
    status: 'Passed'
  },
  {
    id: 2,
    name: 'Php Laravel',
    description: 'Test your PHP Laravel knowledge on routing, controllers, and middleware.',
    duration: "1 hr 15 mins",
    status: 'Not started'
  },
  {
    id: 3,
    name: 'Java',
    description: 'Evaluate your Java skills, including OOP principles, collections, and concurrency.',
    duration: "2 hrs",
    status: 'Failed'
  },
  {
    id: 4,
    name: 'Python',
    description: 'Test your Python knowledge, focusing on advanced topics like decorators, and multithreading.',
    duration: "1 hr 45 mins",
    status: 'Passed'
  },
  {
    id: 5,
    name: 'JavaScript',
    description: 'Evaluate your knowledge of JavaScript including ES6+, closures, and event handling.',
    duration: "1 hr",
    status: 'Passed'
  },
  {
    id: 6,
    name: 'React.js',
    description: 'Test your understanding of React concepts such as hooks, components, and state management.',
    duration: "1 hr 20 mins",
    status: 'Not started'
  },
  {
    id: 7,
    name: 'Node.js',
    description: 'Assess your Node.js skills, focusing on server-side programming, streams, and async patterns.',
    duration: "1 hr 30 mins",
    status: 'Failed'
  },
  {
    id: 8,
    name: 'Vue.js',
    description: 'Evaluate your understanding of Vue.js, focusing on components, directives, and Vuex.',
    duration: "1 hr 10 mins",
    status: 'Passed'
  },
  {
    id: 9,
    name: 'Ruby on Rails',
    description: 'Test your Rails knowledge, covering routing, controllers, and ActiveRecord.',
    duration: "1 hr 25 mins",
    status: 'Failed'
  },
  {
    id: 10,
    name: 'Swift',
    description: 'Assess your Swift programming skills, focusing on iOS app development and SwiftUI.',
    duration: "1 hr 40 mins",
    status: 'Not started'
  },
  {
    id: 11,
    name: 'Kotlin',
    description: 'Test your Kotlin skills, including Android development and coroutines.',
    duration: "1 hr 35 mins",
    status: 'Passed'
  },
  {
    id: 12,
    name: 'C++',
    description: 'Evaluate your knowledge of C++ with a focus on pointers, memory management, and OOP.',
    duration: "2 hrs",
    status: 'Failed'
  },
  {
    id: 13,
    name: 'C#',
    description: 'Test your skills in C#, focusing on .NET framework, LINQ, and async programming.',
    duration: "1 hr 50 mins",
    status: 'Passed'
  },
  {
    id: 14,
    name: 'Go',
    description: 'Assess your Go knowledge, with a focus on concurrency, goroutines, and channels.',
    duration: "1 hr 15 mins",
    status: 'Not started'
  },
  {
    id: 15,
    name: 'Django',
    description: 'Test your understanding of the Django web framework, covering models, views, and ORM.',
    duration: "1 hr 30 mins",
    status: 'Not started'
  }
];


export const assessment_questions = [
  {
    id: 1,
    assessment_id: "1",
    question_text: "Which of the following is a valid JavaScript variable name?",
    question_type: "Multiple Choices",
    created_at: "2024-09-12T10:00:00.000000Z",
    updated_at: "2024-09-12T10:00:00.000000Z",
    options: [
      {
        id: 1,
        question_id: "1",
        option_text: "1stVariable",
        is_correct: "0",
        created_at: "2024-09-12T10:01:00.000000Z",
        updated_at: "2024-09-12T10:01:00.000000Z"
      },
      {
        id: 2,
        question_id: "1",
        option_text: "variable_1",
        is_correct: "1",
        created_at: "2024-09-12T10:01:00.000000Z",
        updated_at: "2024-09-12T10:01:00.000000Z"
      }
    ]
  },
  {
    id: 2,
    assessment_id: "1",
    question_text: "What will the following code output? console.log(typeof NaN);",
    question_type: "Multiple Choices",
    created_at: "2024-09-12T10:05:00.000000Z",
    updated_at: "2024-09-12T10:05:00.000000Z",
    options: [
      {
        id: 1,
        question_id: "2",
        option_text: "undefined",
        is_correct: "0",
        created_at: "2024-09-12T10:05:00.000000Z",
        updated_at: "2024-09-12T10:05:00.000000Z"
      },
      {
        id: 2,
        question_id: "2",
        option_text: "number",
        is_correct: "1",
        created_at: "2024-09-12T10:05:00.000000Z",
        updated_at: "2024-09-12T10:05:00.000000Z"
      }
    ]
  },
  {
    id: 3,
    assessment_id: "1",
    question_text: "Explain how closures work in JavaScript.",
    question_type: "Open",
    created_at: "2024-09-12T10:10:00.000000Z",
    updated_at: "2024-09-12T10:10:00.000000Z"
  },
  {
    id: 4,
    assessment_id: "1",
    question_text: "Write a function that takes an array of numbers and returns the sum of all elements.",
    question_type: "Coding",
    created_at: "2024-09-12T10:15:00.000000Z",
    updated_at: "2024-09-12T10:15:00.000000Z"
  }
];


export const notificationsdummy = [
  {
    id: 1,
    title: 'New Coding Challenge Available',
    message: 'A new coding challenge has been added to your dashboard. Check it out and start solving to improve your certification score!',
    read_status: false,
    created_at: "2024-09-12T10:15:00.000000Z"
  },
  {
    id: 2,
    title: 'Custom Test Assigned',
    message: 'A client has assigned a custom test to you. Please complete it by the specified deadline to move forward in the selection process.',
    read_status: false,
    created_at: "2024-09-12T11:00:00.000000Z"
  },
  {
    id: 3,
    title: 'Certification Achieved!',
    message: 'Congratulations! You have successfully achieved a new certification. Your updated profile is now visible to clients.',
    read_status: true,
    created_at: "2024-09-12T12:30:00.000000Z"
  },
  {
    id: 4,
    title: 'Profile View Alert',
    message: 'Your profile has been viewed by a client. Keep your profile updated and continue to excel in your assessments!',
    read_status: true,
    created_at: "2024-09-12T14:00:00.000000Z"
  },
  {
    id: 5,
    title: 'Feedback Received',
    message: 'You have received feedback on your recent coding challenge. Review the feedback to understand areas of improvement.',
    read_status: true,
    created_at: "2024-09-12T15:45:00.000000Z"
  }
];

export const Billingsample = [
  {
    id: 1,
    invoice: 'Invoice0023',
    amount: 1200,
    status: 'paid',
    created_at: "2024-09-12T15:45:00.000000Z"
  },
  {
    id: 2,
    invoice: 'Invoice0024',
    amount: 1400,
    status: 'unpaid',
    created_at: "2024-09-12T16:00:00.000000Z"
  },
  {
    id: 3,
    invoice: 'Invoice0025',
    amount: 800,
    status: 'paid',
    created_at: "2024-09-13T10:30:00.000000Z"
  },
  {
    id: 4,
    invoice: 'Invoice0026',
    amount: 900,
    status: 'unpaid',
    created_at: "2024-09-14T08:45:00.000000Z"
  },
  {
    id: 5,
    invoice: 'Invoice0027',
    amount: 1500,
    status: 'paid',
    created_at: "2024-09-15T09:20:00.000000Z"
  },
  {
    id: 6,
    invoice: 'Invoice0028',
    amount: 600,
    status: 'unpaid',
    created_at: "2024-09-15T12:10:00.000000Z"
  },
  {
    id: 7,
    invoice: 'Invoice0029',
    amount: 2000,
    status: 'paid',
    created_at: "2024-09-16T11:15:00.000000Z"
  },
  {
    id: 8,
    invoice: 'Invoice0030',
    amount: 1100,
    status: 'unpaid',
    created_at: "2024-09-17T14:30:00.000000Z"
  },
  {
    id: 9,
    invoice: 'Invoice0031',
    amount: 1300,
    status: 'paid',
    created_at: "2024-09-18T10:05:00.000000Z"
  },
  {
    id: 10,
    invoice: 'Invoice0032',
    amount: 1750,
    status: 'unpaid',
    created_at: "2024-09-19T13:40:00.000000Z"
  }
];


export const subscriptionplans = [
  {
    plan_name: "Basic Plan",
    price: "$29/month",
    features: [
      "Access to developer profiles",
      "Search and filter by skills and certifications",
      "Administer up to 5 custom tests per month",
      "View certification scores for developers",
      "Basic client support"
    ]
  },
  {
    plan_name: "Professional Plan",
    price: "$59/month",
    features: [
      "All features of the Basic Plan",
      "Unlimited custom test administration",
      "Access to detailed developer performance analytics",
      "Enhanced search filters (e.g., experience level, specific project expertise)",
      "Priority client support",
      "3% discount on developer hiring fees"
    ]
  },
  {
    plan_name: "Enterprise Plan",
    price: "$99/month",
    features: [
      "All features of the Professional Plan",
      "Personalized developer recommendations based on project needs",
      "Dedicated account manager",
      "Advanced reporting tools for developer assessments",
      "Team collaboration features for hiring managers",
      "Unlimited developer performance tracking",
      "Custom test creation assistance from Kontorva specialists",
      "5% discount on developer hiring fees"
    ]
  },
  {
    plan_name: "Pay-As-You-Go Plan",
    price: "Free to sign up, pay per usage",
    features: [
      "No monthly subscription",
      "Administer custom tests for $10 per test",
      "Access developer profiles and certifications",
      "Basic search and filter capabilities",
      "Pay only when a developer is hired, with no discount on fees"
    ]
  }
];


export const asessmentcandidates = [
  {
    id: 1,
    name: 'Moses Tenai',
    match: 80,
    shortlisted: false,
    completion: false,
    email: 'moses.tenai@example.com',
    city: 'Nairobi',
    country: 'Kenya',
    last_login: '2024-09-10',
    description: 'Experienced software developer with a passion for backend services.',
    speciality: 'Backend'
  },
  {
    id: 2,
    name: 'Faith Mwangi',
    match: 90,
    shortlisted: true,
    completion: true,
    email: 'faith.mwangi@example.com',
    city: 'Mombasa',
    country: 'Kenya',
    last_login: '2024-09-12',
    description: 'Frontend developer specializing in modern JavaScript frameworks.',
    speciality: 'Frontend'
  },
  {
    id: 3,
    name: 'John Otieno',
    match: 75,
    shortlisted: false,
    completion: true,
    email: 'john.otieno@example.com',
    city: 'Kisumu',
    country: 'Kenya',
    last_login: '2024-09-08',
    description: 'Full stack developer with expertise in frontend-heavy applications.',
    speciality: 'Fullstack (FE-Heavy)'
  },
  {
    id: 4,
    name: 'Grace Kamau',
    match: 85,
    shortlisted: true,
    completion: false,
    email: 'grace.kamau@example.com',
    city: 'Nakuru',
    country: 'Kenya',
    last_login: '2024-09-11',
    description: 'Backend developer focused on API development and cloud services.',
    speciality: 'Backend'
  },
  {
    id: 5,
    name: 'Brian Kipchumba',
    match: 95,
    shortlisted: true,
    completion: true,
    email: 'brian.kipchumba@example.com',
    city: 'Eldoret',
    country: 'Kenya',
    last_login: '2024-09-14',
    description: 'Skilled in both frontend and backend with a focus on scalable systems.',
    speciality: 'Fullstack (Balanced)'
  }
];




// def check_odd_even(number):
//     if number % 2 == 0:
//         return "Even"
//     else:
//         return "Odd"

// # Example usage:
// num = 20
// result = check_odd_even(num)
// print(f"The number {num} is {result}.")

