import React, { useEffect, useState } from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import CircularWithValueLabel from "../../../Components/circularprogress";
import { assessmentsdummy, systemassessments } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import { useNavigate } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import { convertTimestampToTime } from "../../../Utils/functions";

const Allmyassessments = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'My Assessments' }))
        }
    }, []);




    const gotoassessments = (assessment) => {
        navigate("/candidateaccount/assessments-module", { state: { assessmentinfo: assessment } })
    }


    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                {/* <Typography style={{ cursor: "pointer" }} onClick={() => navigate(-1)} fontFamily={'boldfont'}>Assessments/</Typography> */}
                <Typography fontFamily={'boldfont'}>All Assessments</Typography>
            </Stack>
            <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                {assessmentsdummy?.map((assessment, key) => {
                    const progresspercentage = Math.round((parseInt(assessment.solved) / parseInt(assessment.quizes)) * 100);

                    return (
                        <Box onClick={() => gotoassessments(assessment)} position={"relative"} height={190} key={key} width={"23%"} margin={"1%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, cursor: "pointer" }}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.name}</Typography>
                                <CircularWithValueLabel value={progresspercentage} />
                            </Stack>
                            <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                {assessment.description}
                            </Typography>
                            <hr color="silver" />
                            <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"}>
                                <Box>
                                    <Typography fontSize={14}>Solved</Typography>
                                    <Typography fontFamily={"boldfont"}>{assessment.solved}</Typography>
                                </Box>
                                <Box>
                                    <Typography fontSize={14}>Problems</Typography>
                                    <Typography fontFamily={"boldfont"}>{assessment.quizes}</Typography>
                                </Box>
                            </Stack>

                            <Typography style={{
                                position: "absolute",
                                right: 2,
                                bottom: 2
                            }} fontFamily={"italiclightfont"} fontSize={12}>{convertTimestampToTime(assessment.created_at)}</Typography>
                        </Box>
                    )
                })}
            </Stack >

        </Box >
    )
}

export default Allmyassessments