import { useMemo, useState } from "react"
import { primarycolor } from "../../../Utils/colors"
import { Box, Stack, Typography, LinearProgress, Button, Slider } from "@mui/material"
import Feedbackbutton from "../../../Components/feedbackbutton"
import { useNavigate } from "react-router-dom"
import { Sitenamelogo } from "../../../Common/sitelogo"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../../../store/user"
import { CheckOutlined } from "@mui/icons-material"
import muistyles from "../../../Utils/muistyles"
import { returnperiod } from "../../../Utils/functions"

const Onboarding4 = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const name = user?.name.split(' ')[0]

    const [professionalexperience, setprofessionalexperience] = useState(user?.professionalexperience || 20);
    const [freelanceexperience, setfreelanceexperience] = useState(user?.freelanceexperience || 12);


    const handleprofessionalchange = (event, newValue) => {
        setprofessionalexperience(newValue);
    };
    const handlefreelancechange = (event, newValue) => {
        setfreelanceexperience(newValue)
    }






    const gotonext = () => {
        const updateddata = { ...user, professionalexperience, freelanceexperience }
        dispatch(setUser(updateddata))
        navigate("/candidate-onboarding-5")
    }


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }

    return (
        <Box>
            <Stack flexDirection={"row"} height={"100vh"}>
                <Box width={"60%"} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"} sx={{ overflowY: "scroll" }}>
                    <Box width={"90%"}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋</Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={60} />
                    </Box>
                    <Box width={"90%"} paddingRight={"10%"} sx={{ overflowY: 'scroll' }} pb={10} height={"70%"}>
                        <Typography mt={4} mb={2} fontSize={30} fontFamily={"boldfont"}>
                            How much full-time, professional
                            experience do you have in {user?.role} roles?
                        </Typography>
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <CheckOutlined color="success" />&nbsp;
                            <Typography fontSize={13}>Paid work in your role after finishing school.</Typography>
                        </Stack>
                        <Stack flexDirection={"row"} alignItems={"center"}>
                            <CheckOutlined color="success" />&nbsp;
                            <Typography fontSize={13}> Doesn’t include internships</Typography>
                        </Stack>
                        <Box mt={3}>
                            <Typography color={'#959593'} fontSize={15} fontFamily={'boldfont'}>Full-time, professional experience</Typography>
                            <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={13}>Use the slider</Typography>
                                <Typography fontSize={13} fontFamily={'boldfont'} color={'primary'}>{returnperiod(professionalexperience)}</Typography>
                            </Stack>
                            <Slider value={professionalexperience} onChange={handleprofessionalchange} />
                            <Stack justifyContent={"space-between"} flexDirection={"row"}>
                                <Typography fontSize={12}>months</Typography>
                                <Typography fontSize={12}>years</Typography>
                            </Stack>
                            <Typography mt={3} color={'#959593'} fontSize={15} fontFamily={'boldfont'}>How much of this has been freelance?</Typography>
                            <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                                <Typography fontSize={13}>Use the slider</Typography>
                                <Typography fontSize={13} fontFamily={'boldfont'} color={'primary'}>{returnperiod(freelanceexperience)}</Typography>
                            </Stack>
                            <Slider value={freelanceexperience} onChange={handlefreelancechange} />
                            <Stack justifyContent={"space-between"} flexDirection={"row"}>
                                <Typography fontSize={12}>months</Typography>
                                <Typography fontSize={12}>years</Typography>
                            </Stack>
                        </Box>

                    </Box>
                    <Stack bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} sx={buttonstyle} variant="contained">Next</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <img style={{ maxHeight: 150 }} src={require("./../../../../assets/illustrations/tailor.png")} />
                    <Typography mt={3} fontFamily={'boldfont'} width={"80%"} color={"white"} fontSize={30} textAlign={"center"}>
                        94% of Kontorva members recommend us
                    </Typography>
                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Onboarding4