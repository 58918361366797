const { useNavigate } = require("react-router-dom")

const Sitelogo = ({ height, type }) => {
    let logolink = require("./../../assets/images/Logo/iconwhite.png")
    if (type === 'dark') {
        logolink = require("./../../assets/images/Logo/iconblack.png")
    }
    if (type === 'transparent') {
        logolink = require("./../../assets/images/Logo/logotrans.png")
    }

    const navigate = useNavigate()
    return (
        <img style={{ height: height }} onClick={() => navigate('/get-started')} className="landingicon" src={logolink} />
    )
}

export default Sitelogo

//site name logo
export const Sitenamelogo = ({ maxWidth = "150px", type }) => {
    let logolink = require("./../../assets/images/Logo/whitelogo.png")

    if (type === "transparentwhite") {
        logolink = require("./../../assets/images/Logo/transparentwhite.png")
    }

    const navigate = useNavigate()
    return (
        <img style={{ maxWidth: maxWidth, cursor: "pointer" }} onClick={() => navigate('/get-started')} src={logolink} />
    )
}