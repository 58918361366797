import { createTheme } from "@mui/material";
import { dimtextcolor, lightprimarycolor, primarycolor, secondarycolor } from "./colors";

const theme = createTheme({
    palette: {
        primary: {
            main: primarycolor
        },
        secondary: {
            main: secondarycolor
        },
        lightprimary: {
            main: lightprimarycolor
        },
        dimcolor: {
            main: dimtextcolor
        }
    },
    typography: {
        fontFamily: `lightfont`
    }
})

export default theme