import { Backdrop, CircularProgress, Container, Typography } from "@mui/material"
import Lottie from "lottie-react";
import loadinglottie from "./../../assets/lotties/tick.json"

const Successspinner = ({ message }) => {

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            {/* <CircularProgress color="inherit" /> */}
            <Container maxWidth="xs" sx={{
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: 5,
                paddingBottom: 8,
                paddingTop: 8
            }}>
                <Lottie animationData={loadinglottie} style={{ height: 100 }} loop={false} autoplay={true} />
                <Typography style={{ fontWeight: "600", color: "gray" }}>{message || 'Success'}</Typography>
            </Container>
        </Backdrop>
    )
}

export default Successspinner