import React, { useEffect, useMemo, useRef, useState } from "react"
import { Box, Button, IconButton, InputAdornment, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Add, Cancel, Clear, DriveFileRenameOutline, East, KeyboardBackspace, LocationOnOutlined, Search, Tune } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes, Questiontypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import { Customcheckbox, Customsectiontextinput, Customselectinput } from "../../../../Components/textinputs.";
import { v4 as uuidv4 } from 'uuid';
import { Erroralert } from "../../../../Components/popups";
import { navigateto } from "../../../../Utils/functions";
import { setNewassessment } from "../../../../../store/newassessment";



const Assessmentbuilder3 = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const { newassessment } = useSelector((state) => state.newassessment);
    const dispatch = useDispatch()
    const [error, seterror] = useState('');

    const defaultoption = { option_text: "", is_correct: false };
    const defaultansweroptions = Array.from({ length: 3 }, () => ({ ...defaultoption }));

    const [customquestions, setcustomquestions] = useState(newassessment?.customquestions || []);

    const [currentQuestionId, setcurrentQuestionId] = useState('');
    const [question_text, setquestion_text] = useState('');
    const [question_type, setquestion_type] = useState('');
    const [answeroptions, setansweroptions] = useState(defaultansweroptions);

    const inputRefs = useRef([]);

    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
    }, []);

    ///auto add answer options
    useEffect(() => {
        const arraylength = answeroptions.length
        const checkfilledoptions = answeroptions.filter((obj) => obj.option_text);
        if (checkfilledoptions.length + 1 >= arraylength) {
            setansweroptions([...answeroptions, defaultoption])
        }
    }, [answeroptions]);



    //handle question adding to custom questions
    const handlequestionadd = () => {
        const existingQuestion = customquestions.find((question) => question.id === currentQuestionId); // Use unique id to find the question

        let filledoptions = answeroptions.filter((obj) => obj.option_text);

        if (question_type === Questiontypes[0] && filledoptions.length < 1) {
            seterror('No answers provided');
            return;
        }

        const questionobject = {
            id: existingQuestion?.id || uuidv4(),  // Reuse existing id if updating, or generate new one
            question_text,
            question_type,
            options: filledoptions,
        };

        if (existingQuestion) {
            // If question exists, update it
            const updatedQuestions = customquestions.map((question) =>
                question.id === currentQuestionId ? questionobject : question
            );
            setcustomquestions(updatedQuestions);
        } else {
            // If question doesn't exist, add it to the array
            setcustomquestions([...customquestions, questionobject]);
        }

        // Clear fields and reset state
        navigateto("top");
        setansweroptions([]);
        setquestion_text('');
        setcurrentQuestionId(null);  // Reset the current question ID for new additions
        seterror(null);
    };



    //remove question
    const removequestion = (key) => {
        setcustomquestions(customquestions.filter((obj, questionkey) => questionkey !== key))
    }


    //move through the form
    const optiontextkeydown = (event, index) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index < answeroptions.length - 1) {
                // If Enter key is pressed and not the last option
                inputRefs.current[index + 1].focus(); // Focus on the next option question input
            }
        } else if (event.key === "Backspace" && index > 0 && answeroptions[index].option_text === "") {
            event.preventDefault();
            inputRefs.current[index - 1].focus(); // Focus on the previous option input
        }
    }

    //on change question text 
    const onchangequestiontext = (text, key, event) => {
        const clonedoptions = [...answeroptions]
        clonedoptions[key].option_text = text
        setansweroptions(clonedoptions)
    }

    //onchange correct answer
    const onchangecorrectanswer = (is_correct, key) => {
        const clonedoptions = [...answeroptions]
        clonedoptions[key].is_correct = !is_correct
        setansweroptions(clonedoptions)
    }

    //remove option
    const removeoption = (optionkey) => {
        setansweroptions(answeroptions.filter((obj, key) => key !== optionkey))
    }

    //setquestion in play
    const setquestioninplay = (question) => {
        setquestion_text(question.question_text)
        setquestion_type(question.question_type)
        setansweroptions(question?.options)
        setcurrentQuestionId(question?.id)
    }



    const gotonext = () => {
        const updatedassessment = { ...newassessment, customquestions }
        dispatch(setNewassessment(updatedassessment))
        navigate("/clientaccount/assessment-builder-4")
    }




    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box width={"100%"}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Custom Questions</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={2} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div id="top" />
            </Box>
            <Box mt={2} padding={4} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Box width={"48%"} component={'form'} onSubmit={(e) => {
                        e.preventDefault()
                        handlequestionadd()
                    }}>
                        <Customselectinput
                            fieldname={"Question Type"}
                            hint={'i.e MultiChoices'}
                            options={Questiontypes}
                            value={question_type}
                            onChange={setquestion_type}
                            width={"100%"}
                        /><br />
                        <Customsectiontextinput
                            fieldname={"Question"}
                            hint={'i.e Define hooks in react'}
                            value={question_text}
                            multiline={true}
                            required={true}
                            rows={3}
                            onChange={setquestion_text}
                            width={"100%"}
                        />
                        {question_type === Questiontypes[0] &&
                            <Box>
                                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                    <Typography>Answer Options</Typography>
                                </Stack>
                                <Box>
                                    {answeroptions.map((answer, key) => {

                                        return (
                                            <Stack key={key} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Customsectiontextinput
                                                    inputRef={(ref) => (inputRefs.current[key] = ref)}
                                                    fieldname={"Option " + (key + 1)}
                                                    handleKeyDown={(e) => optiontextkeydown(e, key)}
                                                    value={answer.option_text}
                                                    onChange={(text) => onchangequestiontext(text, key)}
                                                    width={"80%"}
                                                />
                                                <Customcheckbox onChange={() => onchangecorrectanswer(answer.is_correct, key)} value={answer.is_correct} />
                                                <Clear sx={{ cursor: "pointer" }} onClick={() => removeoption(key)} color="error" />
                                            </Stack>
                                        )
                                    })}
                                </Box>
                            </Box>
                        }
                        <Button type="submit" variant="contained" sx={{ textTransform: "none", mb: 2 }}>Add Question</Button>
                    </Box>
                    <Box width={"48%"} sx={{ height: "60vh", overflowY: "scroll" }}>
                        {customquestions.length > 0 &&
                            <Box>
                                <Typography fontFamily={"boldfont"}>Questions ({customquestions.length})</Typography>
                                {customquestions.map((question, key) => {

                                    return (
                                        <Box position={"relative"} mb={2} padding={2} sx={{ border: boxborder, borderRadius: 3 }} key={key}>
                                            <Stack flexDirection={"row"} position={"absolute"} right={2} top={1}>
                                                <DriveFileRenameOutline color="primary" onClick={() => setquestioninplay(question)} sx={{ cursor: "pointer" }} />&nbsp;
                                                <Cancel titleAccess="click to remove" onClick={() => removequestion(key)} sx={{ cursor: "pointer" }} color="error" />
                                            </Stack>

                                            <Stack flexDirection={"row"}>
                                                <Typography mr={1} fontFamily={"boldfont"}>{key + 1})</Typography>
                                                <Box width={"92%"}>
                                                    <Typography fontFamily={"boldfont"}>{question.question_text}</Typography>
                                                    <Typography >{question.question_type}</Typography>
                                                </Box>
                                            </Stack>

                                        </Box>
                                    )
                                })}
                            </Box>
                        }
                    </Box>
                </Stack>
                <Stack flexDirection={"row"} justifyContent={"space-between"} >
                    <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                    <Button onClick={gotonext} disabled={customquestions?.length < 1} sx={buttonstyle} endIcon={<East />} variant="contained">Next</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Assessmentbuilder3