import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { } from "react-icons/fa";
import Login from './pages/Common/login';
import { useDispatch, useSelector } from 'react-redux';
import { reduxconstants } from './pages/Utils/arrays';
import { logoutUser, setUser } from './store/user';
import { getFromStore } from './pages/Utils/functions';
import Landingscreen from './pages/Common/landingscreeen';
import { setProfiles } from './store/userprofiles';
import Clientnavigation from './navclient';
import Candidatenavigation from './navcandidate';
import Clientsignup from './pages/Common/clientsignup';
import Candidatesignup from './pages/Common/candidatesignup';
import Resetpass from './pages/Common/resetpass';
import Onboarding1 from './pages/Dashboards/Candidate/Onboarding/screen1';
import Onboarding2 from './pages/Dashboards/Candidate/Onboarding/screen2';
import Onboarding3 from './pages/Dashboards/Candidate/Onboarding/screen3';
import Onboarding4 from './pages/Dashboards/Candidate/Onboarding/screen4';
import Onboarding5 from './pages/Dashboards/Candidate/Onboarding/screen5';
import Onboarding6 from './pages/Dashboards/Candidate/Onboarding/screen6';
import Onboarding7 from './pages/Dashboards/Candidate/Onboarding/screen7';
import Onboarding8 from './pages/Dashboards/Candidate/Onboarding/screen8';
import Clientonboarding1 from './pages/Dashboards/Client/Onboarding/clientonboarding1';
import Clientonboarding2 from './pages/Dashboards/Client/Onboarding/clientonboarding2';
import Clientonboarding3 from './pages/Dashboards/Client/Onboarding/clientonboarding3';
import { setNewassessment } from './store/newassessment';

const App = () => {
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    updateredux()
  }, []);

  const updateredux = () => {
    let user = getFromStore(reduxconstants.USER_PROFILE, true);
    let profiles = getFromStore(reduxconstants.USER_PROFILES, true)
    let newassessment = getFromStore(reduxconstants.NEW_ASSESSMENT, false)


    if (user) { dispatch(setUser(user)) }
    if (profiles) { dispatch(setProfiles(profiles)) }
    if (newassessment) { dispatch(setNewassessment(newassessment)) }


  }


  const getinitialroute = () => {
    if (!user?.account_type) {
      return "/get-started"
    } else if (user?.account_type === "CLIENT" && user.verification === "verified") {
      return "/clientaccount"
    } else if (user?.account_type === "CANDIDATE" && user.verification === "verified") {
      return "/candidateaccount/*"
    }
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={getinitialroute()} />} />
      <Route path="/get-started" element={<Landingscreen />} exact />
      <Route path="/login" element={<Login />} exact />
      <Route path="/resetpass" element={<Resetpass />} exact />
      <Route path="/clientsignup" element={<Clientsignup />} exact />
      <Route path="/candidatesignup" element={<Candidatesignup />} exact />
      <Route path="/clientaccount/*" element={<Clientnavigation />} exact />
      <Route path="/candidateaccount/*" element={<Candidatenavigation />} exact />

      {/* candidate */}
      <Route path="/candidate-onboarding-1" element={<Onboarding1 />} exact />
      <Route path="/candidate-onboarding-2" element={<Onboarding2 />} exact />
      <Route path="/candidate-onboarding-3" element={<Onboarding3 />} exact />
      <Route path="/candidate-onboarding-4" element={<Onboarding4 />} exact />
      <Route path="/candidate-onboarding-5" element={<Onboarding5 />} exact />
      <Route path="/candidate-onboarding-6" element={<Onboarding6 />} exact />
      <Route path="/candidate-onboarding-7" element={<Onboarding7 />} exact />
      <Route path="/candidate-onboarding-8" element={<Onboarding8 />} exact />

      {/**Client */}
      <Route path="/client-onboarding-1" element={<Clientonboarding1 />} exact />
      <Route path="/client-onboarding-2" element={<Clientonboarding2 />} exact />
      <Route path="/client-onboarding-3" element={<Clientonboarding3 />} exact />


    </Routes>
  );
}



export default App;
