import { Star, StarBorder, StarHalf } from "@mui/icons-material";

const Ratings = ({ product, starsize }) => {
    const average = (parseInt(product.ratingTotal) / product?.item_ratings?.length)?.toString().substring(0, 3);

    return (
        <div className="ratings">
            <Stars rating={average == 'NaN' ? 3.5 : average} starsize={starsize} />
            <div style={{ fontSize: starsize && starsize - 2 }}>{product.item_ratings?.length > 0 ? `(${product.item_ratings?.length})` : `(10)`}</div>
        </div>
    )
}


export default Ratings

export const Stars = ({ rating, starsize }) => {
    // Calculate the number of full stars and half stars
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;

    // Create an array to store the star components
    const starsArray = [];

    // Add full stars
    for (let i = 0; i < fullStars; i++) {
        starsArray.push(<Star key={i} sx={{ fontSize: starsize, color: "orange" }} />);
    }

    // Add half star if necessary
    if (hasHalfStar) {
        starsArray.push(<StarHalf key="half" sx={{ fontSize: starsize, color: "orange" }} />);
    }

    // Fill the rest with empty stars
    const totalStars = 5;
    while (starsArray.length < totalStars) {
        starsArray.push(<StarBorder sx={{ fontSize: starsize, color: "orange" }} key={starsArray.length} />);
    }

    return <div className="productstars">{starsArray}</div>;
};