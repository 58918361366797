import React, { useEffect, useState } from "react"
import { Box, Button, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import CircularWithValueLabel from "../../../Components/circularprogress";
import { assessmentsdummy, systemassessments } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import { AlarmOn, BorderColorOutlined, NewReleases, ReportProblem, Verified } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Candidateassessments = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [allskills, setallskills] = useState([]);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Skill Assessment' }))
            //retrieve user skills
            const { frameworks, languages, databaseTechnologies, cloudTechnologies } = user
            setallskills([...frameworks, ...languages, ...databaseTechnologies, ...cloudTechnologies])

        }
    }, []);



    const gotoassessments = (assessment) => {
        navigate("/candidateaccount/assessments-module", { state: { assessmentinfo: assessment } })
    }




    return (
        <Box width={"100%"}>
            <Typography fontFamily={'boldfont'}>My Assessment</Typography>
            <Stack flexDirection={"row"} mt={1} justifyContent={"space-between"}  >
                <Box width={"68%"} >
                    <Stack flexDirection={"row"} width={"100%"} justifyContent={"space-between"} >
                        {assessmentsdummy?.slice(0, 3).map((assessment, key) => {
                            const progresspercentage = Math.round((parseInt(assessment.solved) / parseInt(assessment.quizes)) * 100);

                            return (
                                <Box onClick={() => gotoassessments(assessment)} key={key} width={"30%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, cursor: "pointer" }}>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.name}</Typography>
                                        <CircularWithValueLabel value={progresspercentage} />
                                    </Stack>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {assessment.description}
                                    </Typography>
                                    <hr color="silver" />
                                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"}>
                                        <Box>
                                            <Typography fontSize={14}>Solved</Typography>
                                            <Typography fontFamily={"boldfont"}>{assessment.solved}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography fontSize={14}>Problems</Typography>
                                            <Typography fontFamily={"boldfont"}>{assessment.quizes}</Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"right"} alignItems={"flex-end"}>
                        <Button onClick={() => navigate("/candidateaccount/all-my-assessments")} variant="text" sx={{ fontSize: 13 }}>See all</Button>
                    </Stack>
                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontFamily={'boldfont'}>Get Your Skill Certified</Typography>
                    </Stack>
                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} flexWrap={"wrap"}>
                        {systemassessments.map((assessment, key) => {
                            const status = assessment.status

                            return (
                                <Box mb={2} key={key} width={"30%"} height={180} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.name}</Typography>
                                        {status === "Failed" && <NewReleases titleAccess="You Failed" color="error" />}
                                        {status === "Passed" && <Verified titleAccess="Passed" color="success" />}
                                    </Stack>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {assessment.description}
                                    </Typography>
                                    <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                        <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration}</Typography>
                                    </Box>
                                    <Stack mt={2}>
                                        {(status === 'Not started' || status === 'Failed') &&
                                            <Button onClick={() => gotoassessments(assessment)} variant="outlined" color="secondary" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>
                                                {status === 'Not started' ? 'Start Assessment' : 'Re-take Assessment'}
                                            </Button>
                                        }
                                        {status === 'Passed' && <Button variant="contained" color="success" sx={{ fontSize: 13, textTransform: 'none', display: "inline-block" }}>Passed</Button>}
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
                <Box width={"30%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Typography fontFamily={"boldfont"} fontSize={14}>Your Stats</Typography>
                    <Stack mt={2} mb={2} justifyContent={"space-between"}>
                        <Stack width={"100%"} mb={2} alignItems={"center"} flexDirection={"row"} >
                            <img style={{ maxWidth: 50 }} src={require('./../../../../assets/icons/receive.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Points Collected</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>245</Typography>
                            </Box>
                        </Stack>
                        <Stack width={"100%"} mb={2} alignItems={"center"} flexDirection={"row"} >
                            <img style={{ maxWidth: 50 }} src={require('./../../../../assets/icons/rated.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Ratings</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>4.5</Typography>
                            </Box>
                        </Stack>
                        <Stack width={"100%"} alignItems={"center"} flexDirection={"row"}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../../assets/icons/completed.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Taken</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>158</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                    <hr />
                    <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                        <CircularWithValueLabel value={68} />
                        <Box ml={2}>
                            <Typography fontFamily={'boldfont'} fontSize={13}>Complete Your Skills Assessments</Typography>
                            <Typography fontSize={12}>
                                Complete the assessments related to your skills profile to
                                be active and discoverable to recruiters looking for you
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            </Stack>

        </Box>
    )
}

export default Candidateassessments