import { useMemo, useState } from "react"
import { Customreactselectinput, Customsectiontextinput, Customselectinput } from "../../../Components/textinputs."
import { primarycolor } from "../../../Utils/colors"
import countryList from 'react-select-country-list'
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material"
import { Box, Stack, Typography, LinearProgress, Button } from "@mui/material"
import Feedbackbutton from "../../../Components/feedbackbutton"
import { useNavigate } from "react-router-dom"
import { Sitenamelogo } from "../../../Common/sitelogo"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../../../store/user"
import muistyles from "../../../Utils/muistyles"

const Onboarding1 = () => {
    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const name = user?.name.split(' ')[0]
    const navigate = useNavigate()
    const options = useMemo(() => countryList().getData(), [])


    const [country, setcountry] = useState(user?.country || '');
    const [city, setcity] = useState(user?.city || '');

    const [moving, setmoving] = useState(user?.moving || false);


    const gotonext = () => {
        const updateddata = { ...user, country, city, moving }
        dispatch(setUser(updateddata))
        navigate("/candidate-onboarding-2")
    }



    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }

    return (
        <Box>
            <Stack flexDirection={"row"} height={"100vh"}>
                <Box width={"60%"} paddingLeft={"5%"} paddingTop={3} position={"relative"} height={"90%"} sx={{ overflowY: "scroll" }}>
                    <Box width={"90%"}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"} >
                            <Sitenamelogo />
                            <Typography>Hi {name} 👋 </Typography>
                        </Stack><br />
                        <LinearProgress variant="determinate" value={20} />
                        <Typography mt={4} mb={5} fontSize={30} fontFamily={"boldfont"}>Where are you based?</Typography>
                        <Customreactselectinput onChange={(e) => setcountry(e.label)} width={"90%"} options={options} fieldname={'Country'} hint={"i.e kenya"} />
                        <br /><br />
                        {country &&
                            <Box>
                                <Customsectiontextinput autoComplete={"street-address"} width={"90%"} value={city} onChange={setcity} fieldname={'City'} hint={"Nairobi"} />
                                <Stack mt={4} flexDirection={"row"} alignItems={"center"}>
                                    <Box sx={{ cursor: "pointer", alignItems: "center", display: "flex" }} mr={1}>
                                        {moving ?
                                            <CheckBox color="primary" onClick={() => setmoving(false)} /> :
                                            <CheckBoxOutlineBlank onClick={() => setmoving(true)} />
                                        }
                                    </Box>
                                    <Typography fontSize={{ lg: "16px", sm: "13px", xs: "13px" }}>I'm moving to a different timezone in the next 6 months</Typography>&nbsp;
                                </Stack>
                            </Box>
                        }
                    </Box>
                    <Stack bottom={2} position={"absolute"} width={"90%"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Button sx={buttonstyle} variant="outlined">Back</Button>
                        <Button onClick={() => gotonext()} disabled={!country || !city} sx={buttonstyle} variant="contained">Next</Button>
                    </Stack>
                </Box>
                <Box width={"40%"} sx={muistyles.onboardingright}>
                    <img style={{ height: 150, width: 150, borderRadius: "100%" }} src={require("./../../../../assets/images/manimage.jpeg")} />
                    <Typography mt={4} width={"80%"} fontFamily={"italiclightfont"} color={"white"} fontSize={20} textAlign={"center"}>
                        "Kontorva simplifies the hiring process
                        for new clients, making it super easy.
                        They connect me with remarkable brands,
                        interesting individuals, and an incredibly
                        fun internal team."<br /><br />
                        Andrew
                    </Typography>
                    <Typography color={'silver'} fontSize={15}>Fullstack Developer</Typography>
                    <Stack right={"2%"} bottom={"8%"} position={"absolute"} flexDirection={"row"} alignItems={"flex-end"}>
                        <Feedbackbutton />
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Onboarding1