import React from "react";

export const base_url = "https://api.kontorva.com/api/";
export const idebase_url = "https://ide-engine.kontorva.com/"

// export const base_url = "http://192.168.1.13:8000/api/";

//common links
//user Create account url
export const Registeruserurl = base_url + "create-account";
//verify regoster code
export const Verifyregistercodeurl = base_url + "verify-email"
//resend register code
export const Resendregistercodeurl = base_url + "verify-email"
//user login url
export const Loginuserurl = base_url + "sign-in";
//lpgout user
export const Logoutuserurl = base_url + "logoutuser"
//user updateuserinfo url
export const Updateuserinfourl = base_url + "account-profile";
//sync user data
export const Syncuserdataurl = base_url + "syncuserdetails";
//send reset pass request
export const Sendrequestpassurl = base_url + "initiate-reset-password";
//validate reset passcode
export const Validatepasscodeurl = base_url + "validateresetcode"
//validate reset passcode
export const Resetpassurl = base_url + "complete-reset-password"
//delete account
export const Deleteaccounturl = base_url + "deleteaccount"
//restoreaccount
export const Restoreaccounturl = base_url + "restoreuser"
//post image
export const Postimageurl = base_url + "uploadpic"
//fetch assets url
export const Fetchassetsurl = base_url + "assets/"
//get account profile
export const Fetchaccountprofileurl = base_url + "user"


//assessments
export const Executecodeurl = idebase_url + "submissions"
//post assessment url
export const Postassessmenturl = base_url + "add_assessment"

















