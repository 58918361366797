import React, { useState } from "react"
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material"
import { Greetings, returnsurname } from "../../Utils/functions"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import muistyles from "../../Utils/muistyles"
import { boxborder, primarycolor } from "../../Utils/colors"
import { AddCircleOutline, ArrowForward, CheckCircle, ContentCopy, East, TravelExplore, Upgrade } from "@mui/icons-material"
import CircularWithValueLabel from "../../Components/circularprogress"
import { subscriptionplans } from "../../Utils/dummy"

const Clienthome = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [currentplan, setcurrentplan] = useState(subscriptionplans[1]);


    return (
        <Box>
            <Box padding={3} borderRadius={3} mb={2} sx={{ background: `linear-gradient(to right, ${primarycolor}, #57a2df)`, }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Box>
                        <Typography fontSize={20} color={'white'} fontFamily={'boldfont'}>{Greetings()} {returnsurname(user?.name)}</Typography>
                        <Typography color={'white'}>Welcome to your Client dashboard</Typography>
                    </Box>
                    <Button onClick={() => navigate("/clientaccount/discover-candidates")} sx={muistyles?.whitebutton} startIcon={<TravelExplore />} variant="contained">Find Developers</Button>
                </Stack>
            </Box>
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Box width={"68%"}>
                    <Box padding={3} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        <Stack flexDirection={"row"}>
                            <Box width={"60%"} padding={2}>
                                <Typography fontSize={30} fontFamily={'boldfont'}>
                                    Skills-based technical
                                    assessments
                                </Typography>
                                <Typography mb={2}>
                                    Efficient & effective hiring processes,
                                    through through skill-based technical
                                    assessments with integrity.
                                </Typography>
                                <Button onClick={() => navigate("/clientaccount/assessment-builder-1")} startIcon={<AddCircleOutline />} sx={{ textTransform: "none" }} variant="contained">New Assessment</Button>
                            </Box>
                            <img className="client-home-illustration" src={require("./../../../assets/illustrations/mansitting.png")} />
                        </Stack>
                    </Box>
                    <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                        <Box padding={3} width={"48%"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            <Typography mb={1} fontSize={18} fontFamily={'boldfont'}>View your current<br /> assessments</Typography>
                            <Button onClick={() => navigate("/clientaccount/clientassessments")} sx={{ textTransform: "none" }} endIcon={<East />} variant="outlined">Talent Asessments</Button>
                        </Box>
                        <Box padding={3} width={"48%"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            <Typography mb={1} fontSize={18} fontFamily={'boldfont'}>Browse pre-assessed<br /> candidates for your roles</Typography>
                            <Button onClick={() => navigate("/clientaccount/discover-candidates")} sx={{ textTransform: "none" }} endIcon={<East />} variant="outlined">Candidates Catalogue</Button>
                        </Box>
                    </Stack>
                    <Box padding={3} mt={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        <Typography mb={1} fontSize={18} fontFamily={'boldfont'}>
                            Get help in assembling a team for your
                            project
                        </Typography>
                        <Button sx={{ textTransform: "none" }} endIcon={<East />} variant="outlined">Consultation</Button>
                    </Box>



                </Box>
                <Box padding={3} width={"30%"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack flexDirection={"row"}>
                        <Typography fontSize={18} fontFamily={'boldfont'}>Current Assessments Activity</Typography>
                        <CircularWithValueLabel value={40} />
                    </Stack>
                    <Stack mt={2} mb={2} flexDirection={"column"} justifyContent={"space-between"}>
                        <Stack mb={2} width={"100%"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/exam.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>My Assessments</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>12</Typography>
                            </Box>
                        </Stack>
                        <Stack mb={2} width={"100%"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/student.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Candidates</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>20</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack mb={2} mt={2} alignItems={"center"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Typography fontSize={14} fontFamily={'boldfont'}>Subscription</Typography>
                        <Button onClick={() => navigate("/clientaccount/client-account-settings")} variant="outlined" sx={{ height: 30 }} color="secondary" endIcon={<Upgrade />}>Upgrade</Button>
                    </Stack>
                    <Box position={"relative"} sx={{ border: '2px solid blue', cursor: "pointer" }} borderRadius={3} padding={2}>
                        <Box sx={{ position: "absolute", right: 10, top: 4 }}><CheckCircle fontSize="small" color="primary" /></Box>
                        <Typography color={'primary'} fontFamily={"boldfont"} fontSize={14}>{currentplan.plan_name}</Typography>
                        <Typography fontFamily={"boldfont"} fontSize={24}>{currentplan.price}</Typography>
                        <Stack>
                            {currentplan.features.map((feature, key) => {

                                return (
                                    <Stack mb={1} flexDirection={"row"} key={key}>
                                        <CheckCircle fontSize="small" color="primary" />
                                        <Tooltip title={feature} placement="top">
                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    maxWidth: '100%'
                                                }}
                                                whiteSpace={"nowrap"} ml={1} fontSize={13}>{feature}</Typography>
                                        </Tooltip>
                                    </Stack>
                                )
                            })}
                        </Stack>
                    </Box>
                </Box>
            </Stack >
        </Box >
    )
}

export default Clienthome