import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DriveFileRenameOutline, East, KeyboardBackspace, LocationOnOutlined, Search, Tune } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import { Customsectiontextinput } from "../../../../Components/textinputs.";
import Popupdialog from "../../../../Components/popupcomponent";
import Loadingspinner from "../../../../Components/loading";
import { Erroralert, Successalert } from "../../../../Components/popups";
import useAuthAxios from "../../../../../hooks/useAuthAxios";
import { Postassessmenturl } from "../../../../Utils/urls";
import { removeNewassessment } from "../../../../../store/newassessment";




const Assessmentbuilder6 = () => {
    const navigate = useNavigate()
    const axiosInstance = useAuthAxios()
    const { user } = useSelector((state) => state.user);
    const { newassessment } = useSelector((state) => state.newassessment);
    const dispatch = useDispatch()


    const [loading, setloading] = useState(false);
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');


    const [invited_candidates_emails, setinvited_candidates_emails] = useState(newassessment?.invited_candidates_emails || []);

    const [confirmsave, setconfirmsave] = useState(false);

    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
    }, []);

    //if user is not from the pervious final details page
    if (!newassessment.instructions) {
        navigate("/clientaccount/clientassessments")
    }


    const handleSubmission = () => {
        setconfirmsave(false)
        const finalassessmentobject = {
            role: newassessment?.role,
            country: newassessment?.country,
            city: newassessment?.city,
            commitmenttype: newassessment?.commitmenttype,
            systemassessments_ids: newassessment?.selectedasessments?.map((obj) => obj.id) || [],
            questions: newassessment?.customquestions,
            instructions: newassessment?.instructions,
            invited_candidates_emails: invited_candidates_emails
        }
        seterror(null)
        setloading(true)
        axiosInstance.post(Postassessmenturl, finalassessmentobject)
            .then(response => {
                setloading(false)
                setsuccess('Posted successfully.redirecting,,,')
                setTimeout(() => {
                    navigate("/clientaccount/clientassessments")
                    dispatch(removeNewassessment())
                }, 3000);
            }).catch(error => {
                setloading(false)
                seterror(error || "Unable to Submit your assessment.Try again later");
            });
    }





    const gotonext = () => {
        navigate("/clientaccount/assessment-builder-3")
    }

    // role, country, city, commitmenttype


    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box width={"100%"}>
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Submission"}
                    question={"Are you sure you want to submit this assessment?"}
                    successtext={"Submit"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmission}
                />
            }
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Invite Candidates</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={5} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} padding={4} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                {newassessment &&
                    <Box mb={2} >
                        <Typography fontFamily={'boldfont'} mb={2}>Invite your candidates to take the {newassessment?.role} assessment</Typography>
                        <Customsectiontextinput
                            value={invited_candidates_emails}
                            onChange={setinvited_candidates_emails}
                            fieldname={'Emails'}
                            hint={'seperate with commas. i.e johndoe@gmail.com, janedoe@gmail.com'}
                            multiline={true}
                            rows={5}
                            placeholder={"(Optional)"}
                            width={"90%"}
                        />

                    </Box>}
                <Stack flexDirection={"row"} justifyContent={"space-between"} >
                    <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                    <Button onClick={() => setconfirmsave(true)} sx={buttonstyle} variant="contained">Submit</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Assessmentbuilder6