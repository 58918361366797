import { DoneAllOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { GOOGLE_MAPS_APIKEY, OPEN_ROUTE_SERVICE_KEY, reduxsecretKey } from "./defaults";
import * as cheerio from 'cheerio';
import CryptoJS from 'crypto-js';

export function capitalizeFirst(inputString) {
    if (inputString) {
        // Convert the entire string to lowercase
        const lowercasedString = inputString.toLowerCase();

        // Capitalize the first letter
        const capitalizedString = lowercasedString.charAt(0).toUpperCase() + lowercasedString.slice(1);

        return capitalizedString;
    }
}

//currency seperator
export const currencyseperator = (amount) => {
    return amount ? amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0.00;
}


export function imgCheck(img, alt) {
    return new Promise((resolve, reject) => {
        img = encodeURI(img);
        let image = new Image();
        image.src = img;
        image.onload = function (e) {
            resolve(img);
        };
        image.onerror = function (e) {
            resolve(alt);
        };
    });
}


//scroll position
export const useWindowScrollPositions = () => {

    const [scrollPosition, setPosition] = useState({ scrollX: 0, scrollY: 0 })

    useEffect(() => {
        function updatePosition() {
            setPosition({ scrollX: window.scrollX, scrollY: window.scrollY })
        }

        window.addEventListener('scroll', updatePosition)
        updatePosition()

        return () => window.removeEventListener('scroll', updatePosition)
    }, [])

    return scrollPosition
}

//scroll page to the specified section
export const navigateto = (path) => {
    document.getElementById(path).scrollIntoView({ behavior: 'smooth' });
}

//change file to base64
export const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

//change file from base 64 to png
export const base64tofile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}


//check image file extension
export const checkimageextension = (filename) => {
    const fileextension = filename.split('.').pop();
    if (fileextension !== 'jpg' && fileextension !== 'png' && fileextension !== 'jpeg' && fileextension !== 'JPG' && fileextension !== 'PNG' && fileextension !== 'JPEG') {
        return false;
    } else {
        return true;
    }
}


//return greeting
export const Greetings = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
        return "Good morning";
    } else if (currentHour >= 12 && currentHour < 18) {
        return "Good afternoon";
    } else {
        return "Good evening";
    }
}

//copy text to clip board
export function copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {

    }).catch(err => {

    });
}

//return surname 
export const returnsurname = (name) => {
    if (name) {
        const firstname = name.split(' ')[0]
        const lastname = name.split(' ')[1]
        return lastname || firstname
    } else {
        return 'John'
    }
}


//close table menu
export const handleMenuItemClick = (popupState, action) => {
    action();
    popupState.close(); // Close the menu
};

//return last item 
export const lastItem = (array) => {
    if (Array.isArray(array)) {
        const arraylen = array.length
        if (arraylen > 1) {
            return array[arraylen - 1]
        } else {
            return array[0]
        }
    }
}

//check number
export const checknumber = (number) => {
    if (number % 2 === 0) {
        return false
    }
    else {
        return true
    }
}

//return appointment color
export const getstatuscolor = (status) => {
    if (status === "active") {
        return "green"
    }
    if (status === "pending") {
        return "orange"
    }
    if (status === "cancelled") {
        return "red"
    }
    if (status === "completed") {
        return "blue"
    }
    return "gray"
}
// Encrypt and store data in localStorage
export function setToStore(name, data, encrypt) {
    if (typeof data === 'object') {
        data = JSON.stringify(data);
    }
    // Encrypt the data before storing
    const encryptedData = CryptoJS.AES.encrypt(data, reduxsecretKey).toString();
    localStorage.setItem(name, encrypt ? encryptedData : data);
}

// Decrypt and retrieve data from localStorage
export function getFromStore(name, is_encrypted) {
    let encryptedData = localStorage.getItem(name);
    if (!encryptedData) return null;

    try {
        // Decrypt the data
        const bytes = CryptoJS.AES.decrypt(encryptedData, reduxsecretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        // Attempt to parse the decrypted data as JSON
        return JSON.parse(is_encrypted ? decryptedData : encryptedData);
    } catch (error) {
        // If parsing fails, return the raw decrypted data
        return null;
    }
}

// Remove data from localStorage
export function removeFromStore(name) {
    localStorage.removeItem(name);
}

//Mui tabs scroll
export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3, pb: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

//change timestamp to formatted time
export function convertTimestampToTime(timestamp) {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        // Today's date, return time in AM/PM format
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Determine AM or PM
        const amOrPm = hours >= 12 ? "PM" : "AM";

        // Convert hours to 12-hour format
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

        // Pad minutes with leading zero if necessary
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Construct the time string
        return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
    } else if (date.toDateString() === yesterday.toDateString()) {
        // Yesterday's date, return "yesterday"
        return "yesterday";
    } else {
        // Date before yesterday, return formatted date
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString(undefined, options);
    }
}

export function convertdateTotime(timestamp) {
    const date = new Date(timestamp);

    // Format the date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    // Format the time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Return the formatted time and date
    return `${hours}:${minutes} ${ampm} ${day}-${month}-${year}`;
}

export function converttimstamptime(timestamp) {
    const date = new Date(timestamp);

    // Format the time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Return the formatted time and date
    return `${hours}:${minutes} ${ampm}`;
}

export function displaydate(timestamp) {
    const date = new Date(timestamp);

    // Format the date components
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();

    // Return the formatted time and date
    return `${day}-${month}-${year}`;
}

export function timeDifference(timestamp) {
    const now = new Date();
    const then = new Date(timestamp);
    const diffInSeconds = Math.floor((now - then) / 1000);

    const secondsInMinute = 60;
    const secondsInHour = 3600;
    const secondsInDay = 86400;

    if (diffInSeconds < secondsInMinute) {
        return `${diffInSeconds}s ago`;
    } else if (diffInSeconds < secondsInHour) {
        const minutes = Math.floor(diffInSeconds / secondsInMinute);
        return `${minutes}mins`;
    } else if (diffInSeconds < secondsInDay) {
        const hours = Math.floor(diffInSeconds / secondsInHour);
        return `${hours}hrs`;
    } else {
        const days = Math.floor(diffInSeconds / secondsInDay);
        return `${days}days`;
    }
}



//initiate phone call
export function initiateCall(phoneNumber) {
    const telUrl = 'tel:' + phoneNumber;
    window.location.href = telUrl;
}

//initiate mail
export function initiateEmail(email) {
    const emailAddress = email;
    const subject = 'Eazilearn';
    const body = 'Hi';

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the default email client with the pre-filled email draft
    window.location.href = mailtoLink;
}

//return message tick 
export const returntick = (message, id) => {
    if (message.recipientid === id) {
        return '';
    } else {
        if (parseInt(message.readstatus) === 0) {
            return (<FaCheck color="silver" />)
        } else {
            return (<DoneAllOutlined color="primary" />)
        }
    }

}


//get file type
export function getFileType(filename) {
    if (filename) {
        const imageExtensions = [".png", ".jpg", ".jpeg", ".gif"]; // List of image file extensions
        const videoExtensions = [".mp4", ".avi", ".mov"]; // List of video file extensions

        const fileExtension = filename?.substring(filename.lastIndexOf(".")).toLowerCase();

        if (imageExtensions.includes(fileExtension)) {
            return "image";
        } else if (videoExtensions.includes(fileExtension)) {
            return "video";
        } else {
            return "file";
        }
    }
}


//Download either file or image from link
export const downloadFile = async (fileUrl, fileName, fileType) => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;

        // Set the appropriate download attributes for cross-browser compatibility
        link.setAttribute('download', fileName);
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');

        // Set the appropriate MIME type based on the file type
        if (fileType === 'image') {
            link.type = 'image/jpeg'; // Set the MIME type specifically for image/jpeg
        } else if (fileType === 'pdf') {
            link.type = 'application/pdf'; // Set the MIME type specifically for PDF files
        } else {
            link.type = 'application/octet-stream';
        }

        link.click();
        URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error occurred while downloading the file:', error);
    }
}

//remove file name extension
export function removeExtension(filename) {
    const t1 = filename.substring(0, filename.lastIndexOf('.')) || filename;
    const t2 = t1.replace(/\./g, "");
    return t2;
}



//compress and image
export const compressImage = (image) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const maxWidth = 800;
                const maxHeight = 800;
                let width = img.width;
                let height = img.height;

                if (width > height && width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                } else if (height > maxWidth) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        const compressedFile = new File([blob], image.name, { type: image.type });
                        const sizeInKB = Math.round(compressedFile.size / 1024); // Convert size to KB
                        resolve({ compressedFile, sizeInKB });
                    },
                    image.type,
                    0.8 // Specify the desired image quality (optional)
                );
            };

            img.onerror = (error) => {
                reject(error);
            };

            img.src = event.target.result;
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(image);
    });
};


//get website data
export const getWebsiteData = async (link) => {
    try {
        const response = await axios.get(link, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        const html = response.data;

        // Load the HTML into Cheerio
        const $ = cheerio.load(html);

        // Get the favicon
        const favicon = $('link[rel="icon"]').attr('href');

        // Get the title
        const title = $('title').text();

        // Get the meta description
        const description = $('meta[name="description"]').attr('content');

        return { favicon, title, description };
    } catch (error) {
        console.error('Error fetching website data:', error);
        return null;
    }
};


//get website links from a text
export const getWebsiteLinks = (text) => {
    const regex = /(?:^|\s)(https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})(?:\s|$)/gi;
    const matches = text.match(regex);
    if (matches) {
        return matches.filter(link => !/\.(png|jpe?g|gif|svg|pdf)$/i.test(link));
    }
    return [];
};



//get distance and time between two locations in  amap
export async function getDistanceAndTime(originLat, originLng, destLat, destLng, pickupdate, pickuptime) {
    const url = `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${OPEN_ROUTE_SERVICE_KEY}&start=${originLng},${originLat}&end=${destLng},${destLat}`;

    const response = await axios.get(url);
    const data = response.data;

    if (data.features.length > 0) {
        const route = data.features[0];
        const distance = route.properties.summary.distance / 1000; // in km
        const duration = route.properties.summary.duration / 60; // in minutes
        const finalduration = addCurrentTimeToDuration(duration, pickupdate, pickuptime)
        const price = roundToNearest5(calculateprice(parseFloat(distance)));
        //  addCurrentTimeToDuration(route.legs[0].duration.text);
        return { distance, finalduration, price };
    }

    // const url = `https://maps.googleapis.com/maps/api/directions/json?origin=${originLat},${originLng}&destination=${destLat},${destLng}&key=${GOOGLE_MAPS_APIKEY}`;
    // const response = await axios.get(url);
    // const data = response.data;

    // if (data.status === "OK") {
    //     const route = data.routes[0];
    //     const distance = route.legs[0].distance.text;
    //     const duration = addCurrentTimeToDuration(route.legs[0].duration.text, pickupdate, pickuptime)
    //     const price = roundToNearest5(calculateprice(parseFloat(distance.replace(/\s*km\s*/gi, ''))));
    //     //  addCurrentTimeToDuration(route.legs[0].duration.text);
    //     return { distance, duration, price };
    // } else {
    //     throw new Error(data.status);
    // }

}

export function addCurrentTimeToDuration(duration, pickupdate, pickuptime) {
    // Convert the date string to a Date object
    var date = new Date(pickupdate);

    // Extract the time components from pickuptime
    var timeComponents = pickuptime?.split(':');
    var hours = parseInt(timeComponents[0], 10);
    var minutes = parseInt(timeComponents[1], 10);

    // Set the time in the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Convert the duration to minutes (assuming duration is now in minutes)
    const durationMinutes = parseInt(duration, 10);

    // Add the duration minutes to the date object
    date.setMinutes(date.getMinutes() + durationMinutes);

    // Return the new time in 'hh:mm AM/PM' format
    return date.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
}


// export function addCurrentTimeToDuration(duration, pickupdate, pickuptime) {

//     // Convert the date string to a Date object
//     var date = new Date(pickupdate);

//     // Extract the time components
//     var timeComponents = pickuptime?.split(':');
//     var hours = parseInt(timeComponents[0], 10);
//     var minutes = parseInt(timeComponents[1], 10);

//     // Set the time in the Date object
//     date.setHours(hours);
//     date.setMinutes(minutes);


//     const durationRegex = /(?:([0-9]+)\s+hours?\s+)?(?:([0-9]+)\s+mins?)?/;
//     const matches = duration.match(durationRegex);

//     if (matches) {
//         const hours = matches[1] ? parseInt(matches[1], 10) : 0;
//         const minutes = matches[2] ? parseInt(matches[2], 10) : 0;

//         const currentTime = date;
//         const arrivalTime = new Date();
//         arrivalTime.setHours(currentTime.getHours() + hours);
//         arrivalTime.setMinutes(currentTime.getMinutes() + minutes);

//         return arrivalTime.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' });
//     }

//     return null; // Invalid duration format
// }

function roundToNearest5(number) {
    // Step 1: Divide by 5
    const dividedBy5 = number / 5;

    // Step 2: Round to the nearest integer
    const rounded = Math.round(dividedBy5);

    // Step 3: Multiply by 5
    const result = rounded * 5;

    return result;
}



//calculate distance between user and the driver
export function calcCrow(lat1, lon1, lat2, lon2) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d?.toFixed(2);

}
function toRad(Value) {
    return Value * Math.PI / 180;
}


//calculate package price
export function calculateprice(distance) {
    let fixedprice;

    if (distance <= 10) {
        fixedprice = 38;
    } else if (distance > 10 && distance <= 20) {
        fixedprice = 35;
    } else {
        fixedprice = 32;
    }

    var price = Math.round(distance * fixedprice);
    return price;
}

//minutes to hours
export function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${hours}hrs, ${Math.round(minutes)}min`;
}

export function openurl(url) {
    window.open(url, '_blank');
}



export function Makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

//calculate ets
export const calculateEta = (distance) => {
    var fixedtime = 3
    var time = Math.round(distance * fixedtime);

    return toHoursAndMinutes(time)
}

//convert months to years
export const returnperiod = (months) => {
    if (months <= 23) {
        return `${months} months`;
    } else {
        const years = Math.floor(months / 12); // Calculate full years
        return `${years} Years`;
    }
};

//format date range
export const formatDateRange = (startdate, enddate) => {
    const options = { month: 'long', year: 'numeric' };
    const start = new Date(startdate).toLocaleDateString('en-US', options);
    const end = new Date(enddate).toLocaleDateString('en-US', options);
    return `${start} - ${end}`;
};

//return errror message =
export const returnErrormessage = (error) => {
    return error?.response?.data?.error_details?.message || 'Unexpected error occurred.Try again later'
}

//stop watch
export const CustomStopwatch = ({ minutes, ontimeup }) => {
    const [seconds, setSeconds] = useState(minutes * 60);

    useEffect(() => {
        if (seconds <= 0) {
            if (ontimeup) ontimeup(); // Call the callback when time is up
            return;
        }

        const interval = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [seconds, ontimeup]);

    const formatTime = () => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        let formattedTime = '';

        if (hrs > 0) {
            formattedTime += `${hrs} hr `;
        }

        if (mins > 0) {
            formattedTime += `${mins} min `;
        }

        formattedTime += `${secs} s`;

        return formattedTime;
    };

    return formatTime();
};

