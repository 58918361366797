import { useEffect, useRef, useState } from "react"
import { KeyboardBackspace, PlayArrow } from "@mui/icons-material"
import { Box, Button, Checkbox, CircularProgress, Stack, TextField, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import { boxborder } from "../../../Utils/colors"
import Popupdialog from "../../../Components/popupcomponent"
import { assessment_questions } from "../../../Utils/dummy"
import { CustomStopwatch, navigateto } from "../../../Utils/functions"
import { alphabets, editorsupportedlanguages, editorthemes, phpSuggestions, Questiontypes } from "../../../Utils/arrays"
import { Customcheckbox, Customreactselectinput, Customselectinput } from "../../../Components/textinputs."
import Editor, { DiffEditor, useMonaco, loader } from '@monaco-editor/react';
import { Erroralert } from "../../../Components/popups"
import { useSelector } from "react-redux"
import axios from "axios"
import { rapidapikey } from "../../../Utils/defaults"
import useAuthAxios from "../../../../hooks/useAuthAxios"
import { Executecodeurl, idebase_url } from "../../../Utils/urls"
var base64 = require('base-64');


const Assessmentmodule = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const assessmentinfo = location.state?.assessmentinfo

    const [error, seterror] = useState('');

    const [screen, setscreen] = useState(1);

    const instructions = [
        " There's no option to pause. Make sure you will not be interrupted for 120 minutes",
        "If you accidentally close your browser, use the invitation link to get back to your test."
    ]

    const gottonext = () => {
        setscreen((prev) => prev + 1)
    }

    const ontimeup = () => {

    }


    return (
        <Box width={"100%"}>
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Stack flexDirection={"row"}>
                    <KeyboardBackspace onClick={() => screen === 2 ? seterror('Cannot go back in between a test') : navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                    <Typography fontFamily={'boldfont'}>{assessmentinfo?.name}</Typography>
                </Stack>
                <Typography fontFamily={'boldfont'}>
                    {screen === 2 && <CustomStopwatch minutes={200} ontimeup={ontimeup} />}
                </Typography>
            </Stack>
            <Box mt={2} width={"100%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                {screen === 1 && <Screenone instructions={instructions} gottonext={gottonext} />}
                {screen === 2 && <Questionscomponent questions={assessment_questions} />}
            </Box>
        </Box>
    )
}


export default Assessmentmodule

//Screen One
const Screenone = ({ instructions, gottonext }) => {


    const [confirmproceed, setconfirmproceed] = useState(false);

    return (
        <Box>
            {confirmproceed &&
                <Popupdialog
                    toptext={"Confirm Assessment Start"}
                    question={"Are you sure you want to begin this assesment?"}
                    successtext={"Proceed"}
                    oncancel={() => setconfirmproceed(false)}
                    onsuccess={() => {
                        gottonext()
                        setconfirmproceed(false)
                    }}
                />
            }
            <Typography fontFamily={'boldfont'}>Before you begin</Typography>
            <Box mt={2}>
                {instructions.map((instruction, key) => {

                    return (
                        <Stack flexDirection={"row"}>
                            <Typography fontFamily={'boldfont'}>{key + 1}.</Typography>
                            <Typography ml={1}>{instruction}</Typography>
                        </Stack>
                    )
                })}
                <Box on mt={2}>
                    <Button onClick={() => setconfirmproceed(true)} variant="outlined" >Start Assessment</Button>
                </Box>
            </Box>
        </Box>
    )
}




const Questionscomponent = ({ questions }) => {
    const [runningcode, setrunningcode] = useState(false);
    const [codeoutput, setcodeoutput] = useState(false);
    const [error, seterror] = useState('');

    const [allquestions, setallquestions] = useState(questions || []);
    const [currentquestion, setcurrentquestion] = useState(questions[0]);

    const currentid = (allquestions.findIndex((obj) => obj.id === currentquestion.id)) + 1
    const question_type = currentquestion.question_type

    const [currenttheme, setcurrenttheme] = useState(editorthemes[1]);
    const [currentlanguage, setcurrentlanguage] = useState(editorsupportedlanguages[0]);

    useEffect(() => {
        if (currentlanguage && currentquestion.question_type === Questiontypes[2]) {
            setcurrentquestion({ ...currentquestion, questionanswer: currentlanguage.sampleCode })
        }
    }, [currentlanguage]);

    useEffect(() => {
        if (codeoutput) {
            ///scroll to the output box
            navigateto('boxend')
        }
    }, [codeoutput]);


    const gotonext = () => {
        if (currentid === allquestions.length) {//if its the last question

        } else {
            const clonedquestions = [...allquestions]
            clonedquestions[currentid - 1] = currentquestion
            const nextquestion = allquestions[currentid]
            setallquestions(clonedquestions)
            setcurrentquestion({ ...nextquestion, questionanswer: nextquestion.question_type === Questiontypes[2] ? currentlanguage.sampleCode : '' })
        }
    }


    const gotback = () => {
        setcurrentquestion(allquestions[currentid - 2])
    }

    // onchnge multiple selection
    const selectoption = (option) => {
        setcurrentquestion({ ...currentquestion, selectedoptionid: option.id })
    }
    //onchange code input and text input
    const handleCodeeditorchange = (value, event) => {
        setcurrentquestion({ ...currentquestion, questionanswer: value })
    }

    //check if question has been answered
    const isquestionansered = () => {
        if (!currentquestion?.selectedoptionid && !currentquestion?.questionanswer) {
            return false
        }
        return true
    }



    //run code
    const runcode = async () => {
        setcodeoutput('');
        setrunningcode(true);

        await axios.post(Executecodeurl, {
            language_id: currentlanguage?.id,
            source_code: currentquestion?.questionanswer,
            stdin: ''
        }).then((response) => {
            const submission_token = response.data.token
            if (submission_token) {
                checkSubmissionStatus(response.data.token);
            } else {
                setrunningcode(false);
                seterror('An error occurred executing your code')
            }
        }).catch((error) => {
            setrunningcode(false);
            seterror('Compiler Unreachable');
            console.error(error);
        })
    };



    const checkSubmissionStatus = async (submission_token) => {
        await axios.get(`${Executecodeurl}/${submission_token}`, {
            params: {
                base64_encoded: 'true',
                fields: '*'
            }
        }).then((response) => {
            const { status, stdout, stderr, message } = response.data;

            if (status.description === 'Processing' || status.description === 'In Queue') {
                // Check status again after 1 second
                setTimeout(() => checkSubmissionStatus(submission_token), 1000);
            } else {
                setrunningcode(false);
                if (!stderr) {
                    setcodeoutput(stdout ? base64.decode(stdout) : 'Code executed successfully with no output');
                } else {
                    seterror(base64.decode(message));
                }
            }
        }).catch(error => {
            setrunningcode(false);
            seterror('Error compiling your code');
        })
    };






    const buttonstyle = { borderRadius: 10, height: 40, width: 100, fontFamily: "boldfont" }

    return (
        <Box width={"100%"} height={"70vh"} >
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            <Box height={"90%"} sx={{ overflowY: "scroll", overflowX: 'hidden' }} position={"relative"}>
                <Stack flexDirection={"row"}>
                    <Typography>{currentid}</Typography>
                    <Typography ml={1}>{currentquestion.question_text}</Typography>
                </Stack>
                <Box mt={2} height={"100%"}>
                    {question_type === Questiontypes[1] &&
                        <Box>
                            <TextField
                                label="Type your answer"
                                multiline
                                rows={8}
                                variant="outlined"
                                value={currentquestion?.questionanswer || ''}
                                onChange={(e) => handleCodeeditorchange(e.target.value)}
                                onPaste={(e) => {
                                    e.preventDefault()
                                    seterror('Pasting not allowed')
                                }}
                                fullWidth
                            />
                        </Box>}
                    {question_type === Questiontypes[0] &&
                        <Box padding={2}>
                            {currentquestion.options.map((option, optionkey) => {
                                const isselected = option.id === currentquestion?.selectedoptionid

                                return (
                                    <Stack width={"100%"} onClick={() => selectoption(option)} key={optionkey} mb={3} flexDirection={'row'} alignItems={"center"} justifyContent={"space-between"} sx={{ cursor: "pointer" }}>
                                        <Stack flexDirection={"row"}>
                                            <Typography>{alphabets[optionkey]})</Typography>
                                            <Typography ml={1}>{option.option_text}</Typography>
                                        </Stack>
                                        <Customcheckbox type={'radio'} value={isselected} />
                                    </Stack>
                                )
                            })}
                        </Box>
                    }
                    {question_type === Questiontypes[2] &&
                        <Box height={"100%"}>
                            <Stack mb={3} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Customreactselectinput
                                    fieldname={'Language'}
                                    options={editorsupportedlanguages}
                                    width={'40%'}
                                    value={currentlanguage}
                                    onChange={setcurrentlanguage}
                                />
                                <Customselectinput
                                    options={editorthemes}
                                    fieldname={'Theme'}
                                    width={'40%'}
                                    value={currenttheme}
                                    onChange={setcurrenttheme}
                                />
                            </Stack>
                            <Editor
                                theme={currenttheme}
                                height="80%"
                                defaultLanguage={'javascript'}
                                language={currentlanguage?.value}
                                defaultValue={currentlanguage?.sampleCode}
                                value={currentquestion?.questionanswer}
                                onChange={handleCodeeditorchange}
                            />

                        </Box>
                    }
                    {question_type === Questiontypes[2] && codeoutput &&
                        <Box>
                            <Typography fontFamily={'boldfont'}>Output</Typography>
                            <Box mt={2}>
                                {codeoutput}
                            </Box>
                            <div id="boxend" style={{ marginTop: 100 }} />
                        </Box>
                    }

                </Box>

            </Box>
            <Stack mt={2} width={"100%"} sx={{ backgroundColor: "white" }} flexDirection={"row"} justifyContent={"space-between"}>
                <Button disabled={currentid === 1} sx={buttonstyle} onClick={() => gotback()} variant="outlined">Back</Button>
                <Stack flexDirection={"row"}>
                    {question_type === Questiontypes[2] && currentlanguage?.id !== 0 &&
                        <Button disabled={!currentquestion?.questionanswer} sx={{ ...buttonstyle, width: 150 }} startIcon={<PlayArrow />}
                            onClick={() => runcode()} variant="outlined">
                            {runningcode ? <CircularProgress color="primary" size={20} /> : 'RUN CODE'}
                        </Button>}&nbsp;&nbsp;
                    <Button disabled={!isquestionansered()} onClick={() => gotonext()} sx={buttonstyle} variant="contained">Next</Button>
                </Stack>
            </Stack>
        </Box>
    )
}
