import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { boxborder } from "../../../Utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../../store/user";
import { Customsectiontextinput, Customselectinput } from "../../../Components/textinputs.";
import { feedbackaffectedFeatures } from "../../../Utils/arrays";
import { Stars } from "../../../Components/ratingcomp";


const Receivefeedback = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [feedbacktype, setfeedbacktype] = useState('');
    const [affected_feature, setaffected_feature] = useState('');
    const [description, setdescription] = useState('');
    const [rating, setrating] = useState('');

    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Feedback' }))
        }
    }, []);

    const feedbacktypes = ['Feedback', 'Feature Request', 'Support Ticked', 'Bug Report']

    return (
        <Box width={"100%"}  position={"relative"} padding={6} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
            <Typography fontSize={25} fontFamily={'boldfont'}>We Value Your Feedback!</Typography>
            <Typography>
                Please take a moment to share your feedback with us. Your input will help us make kontorva even better!
            </Typography>
            <Box mt={3} component={'form'}>
                <Customselectinput
                    required={true}
                    fieldname={'Type Of Feedback*'}
                    width={"100%"}
                    options={feedbacktypes}
                    value={feedbacktype}
                    onChange={setfeedbacktype}
                /><br /><br />
                <Customselectinput
                    required={true}
                    fieldname={'Affected feature*'}
                    width={"100%"}
                    options={feedbackaffectedFeatures}
                    value={affected_feature}
                    onChange={setaffected_feature}
                /><br /><br />
                <Customsectiontextinput
                    required={true}
                    fieldname={'Briefly Describe Your Experience*'}
                    placeholder={'Give a brief description about your experience'}
                    width={"100%"}
                    multiline={true}
                    rows={5}
                    value={description}
                    onChange={setdescription}
                    helperText={'10 Characters Min'}
                />
                <Stack flexDirection={"row"} justifyContent={"right"} width={"100%"}>
                    <Button variant="contained" type="submit">Submit</Button>
                </Stack>

            </Box>
        </Box>
    )
}

export default Receivefeedback