import React, { useState } from "react";
import { Box, Button, Container, IconButton, Modal, Stack, Typography } from "@mui/material"
import { Customreactselectinput, Customsectiontextinput } from "../../../Components/textinputs.";
import { Clear } from "@mui/icons-material";
import { ITDegrees, ITPrograms } from "../../../Utils/arrays";
import Popupdialog from "../../../Components/popupcomponent";

//add education modal
export const Addeducationmodal = ({ ondismiss, onsuccess }) => {

    const [school, setschool] = useState('');
    const [program, setprogram] = useState('');
    const [degree, setdegree] = useState('');
    const [startdate, setstartdate] = useState('');
    const [enddate, setenddate] = useState('');
    const [description, setdescription] = useState('');

    const [confirmsave, setconfirmsave] = useState(false);

    const handleSubmit = () => {
        setconfirmsave(false)
        ondismiss()
    }


    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 1 }}>
                {confirmsave &&
                    <Popupdialog
                        toptext={"Confirm Details!!"}
                        question={"Are you sure the details are correct?"}
                        successtext={"Add education"}
                        oncancel={() => setconfirmsave(false)}
                        onsuccess={handleSubmit}
                    />
                }
                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Typography fontSize={16} fontFamily={"boldfont"}>Add your education details</Typography>
                    <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Box mt={2} onSubmit={(e) => {
                    e.preventDefault()
                    setconfirmsave(true)
                }} component={'form'}>
                    <Customsectiontextinput
                        width={"100%"}
                        value={school}
                        required={true}
                        onChange={setschool}
                        fieldname={'School'}
                        placeholder={"Which school have you studied at?"}
                        hintcolor="red"
                        hint={"*"}
                    />
                    <Customreactselectinput
                        onChange={setprogram}
                        value={program}
                        required={true}
                        width={"100%"}
                        options={ITPrograms}
                        fieldname={'Program'}
                        hint={"*"}
                    /><br />
                    <Customreactselectinput
                        onChange={setdegree}
                        value={degree}
                        required={true}
                        width={"100%"}
                        options={ITDegrees}
                        fieldname={'Degree'}
                        hint={"*"}
                    />
                    <Stack width={"100%"} mt={4} flexDirection={"row"} justifyContent={"space-between"}>
                        <Customsectiontextinput
                            width={"48%"}
                            value={startdate}
                            required={true}
                            type={'date'}
                            onChange={setstartdate}
                            fieldname={'Starting From'}
                            hintcolor="red"
                            hint={"*"}
                        />
                        <Customsectiontextinput
                            width={"48%"}
                            value={enddate}
                            required={true}
                            type={'date'}
                            onChange={setenddate}
                            fieldname={'Ending In'}
                            hintcolor="red"
                            hint={"*"}
                        />
                    </Stack>
                    <Customsectiontextinput
                        width={"100%"}
                        placeholder={'Write about this education'}
                        value={description}
                        required={true}
                        type={'text'}
                        onChange={setdescription}
                        fieldname={'Description'}
                        hintcolor="red"
                        multiline={true}
                        hint={"*"}
                    />
                    <Stack mt={3} flexDirection={"row"} justifyContent={"right"} alignItems={'flex-end'}>
                        <Button onClick={() => ondismiss()} variant="text">Cancel</Button>&nbsp;
                        <Button type="submit" variant="contained">Save</Button>
                    </Stack>
                </Box>

            </Container>
        </Modal>
    )
}
