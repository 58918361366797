import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Clear, FiberManualRecord, Notifications, NotificationsActive, Search } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Container, Modal, Stack } from '@mui/material';
import { primarycolor } from '../../../Utils/colors';
import { Normalrectangleskeleton } from '../../../Components/skeletons';
import { notificationsdummy } from '../../../Utils/dummy';
import { convertdateTotime, converttimstamptime } from '../../../Utils/functions';





export default function Notificationsmodal({ logout, counter }) {
    const { user } = useSelector((state) => state.user);

    const [loading, setloading] = useState(true);

    const [tempnotification, settempnotification] = useState('');
    //modals
    const [shownotification, setshownotification] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setloading(false)
        }, 4000);
    }, []);

    const onviewnotification = (value) => {
        settempnotification(value)
        setshownotification(true)
    }


    const avatarstyle = { width: 32, height: 32, backgroundColor: 'black' }
    return (
        <PopupState variant="popover" popupId="demo-popup-menu" >
            {(popupState) => (
                <React.Fragment>
                    {shownotification && <Viewnotification ondismiss={() => setshownotification(false)} notification={tempnotification} />}
                    <IconButton {...bindTrigger(popupState)} size="small" sx={{ position: 'relative' }} >
                        <FiberManualRecord sx={{ position: "absolute", right: -1, top: -2, zIndex: 5 }} fontSize='small' color='error' />
                        <Avatar sx={avatarstyle} >
                            <Notifications sx={{ color: 'white' }} />
                        </Avatar>
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                        <Box width={250}>
                            <Box sx={{ backgroundColor: primarycolor }} padding={2}>
                                <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                                    <Typography color={'white'}>Notifications</Typography>
                                    <NotificationsActive sx={{ color: "white" }} />
                                </Stack>
                            </Box>
                            <Box mt={1} padding={2}>
                                {!loading &&
                                    <Box>
                                        {notificationsdummy.map((val, key) => {

                                            return (
                                                <Stack onClick={() => onviewnotification(val)} sx={{ cursor: "pointer" }} position={'relative'} key={key} flexDirection={"row"} mb={2}>
                                                    {!val.read_status &&
                                                        <FiberManualRecord color='primary' sx={{ position: "absolute", right: 0, top: 0, zIndex: 5, fontSize: 14 }} />
                                                    }
                                                    <Avatar>
                                                        <Typography>{val?.title?.substring(0, 1)}</Typography>
                                                    </Avatar>
                                                    <Box ml={2}>
                                                        <Typography fontSize={12} fontFamily={'boldfont'}>{val.title}</Typography>
                                                        <Typography fontSize={12}>
                                                            {val.message.substring(0, 30)}...
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            )
                                        })}
                                    </Box>}
                                {loading && Array.from({ length: 2 }, (_, index) => (
                                    <Normalrectangleskeleton key={index} />
                                ))}

                                {/* <Typography>You have no Notifications yet</Typography> */}
                            </Box>
                        </Box>
                    </Menu>
                </React.Fragment>
            )}
        </PopupState>
    );
}

const Viewnotification = ({ notification, ondismiss }) => {


    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 6, borderRadius: 2, pb: 3, pt: 1 }}>
                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} >
                    <Typography fontSize={16} fontFamily={"boldfont"}>{notification.title}</Typography>
                    <IconButton size="small" sx={{ backgroundColor: 'silver' }} onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Box mt={2}>
                    <Typography fontSize={14}>{notification.message}</Typography>
                    <Stack mt={2} flexDirection={"row"} justifyContent={"right"}>
                        <Typography fontSize={12}>
                            {convertdateTotime(notification.created_at)} &nbsp;
                        </Typography>
                    </Stack>
                </Box>
            </Container>
        </Modal>
    )
}