import { createSlice } from '@reduxjs/toolkit';
import { removeFromStore, setToStore } from '../pages/Utils/functions';
import { reduxconstants } from '../pages/Utils/arrays';

export const assessmentSlice = createSlice({
    name: reduxconstants.NEW_ASSESSMENT,
    initialState: {
        newassessment: null,
    },
    reducers: {
        setNewassessment: function (state, action) {
            state.newassessment = action.payload;
            setToStore(reduxconstants.NEW_ASSESSMENT, action.payload, false);
        },
        removeNewassessment: function (state) {
            state.newassessment = null;
            removeFromStore(reduxconstants.NEW_ASSESSMENT)
        },
    },
});

export const { setNewassessment, removeNewassessment } = assessmentSlice.actions;
export default assessmentSlice.reducer;
