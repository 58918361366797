import React, { useState, useEffect } from "react";
import muistyles from "../Utils/muistyles";
import { Box, Button, Container, IconButton, Modal, Stack, Typography } from "@mui/material";
import useAuthAxios from "../../hooks/useAuthAxios";
import { Resendregistercodeurl, Verifyregistercodeurl } from "../Utils/urls";
import Loadingspinner from "../Components/loading";
import { Erroralert, Successalert } from "../Components/popups";
import VerificationInput from "react-verification-input";
import { primarycolor } from "../Utils/colors";
import { Clear } from "@mui/icons-material";

const Verifyregistercode = ({ ondismissmodals, useremail, onsuccess }) => {
    const axiosInstance = useAuthAxios()

    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');

    const [progress, setprogress] = useState(false);
    const [code, setcode] = useState(0);
    const [counter, setCounter] = useState(30);
    const [resendcode, setresendcode] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
                if (counter === 1) {
                    setresendcode(true)
                }
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [counter]);


    //verify code
    const validatecode = (event) => {
        setprogress(true)
        seterror(null)
        event.preventDefault()
        axiosInstance.post(Verifyregistercodeurl, {
            email: useremail,
            otp: code
        }).then(response => {
            setprogress(false)

            setsuccess('Code validated sucessfully.Proceed to login.')
            setTimeout(() => {
                onsuccess()
            }, 4000);

        }).catch(error => {
            setprogress(false)
            seterror(error || "Sorry an error occurred,try again later");
        });
    }

    //send code
    const sendcode = () => {
        setresendcode(false)
        setprogress(true)
        axiosInstance.post(Resendregistercodeurl, {
            email: useremail
        }).then(function (response) {
            setprogress(false)
            setsuccess("Code sent")
            setTimeout(() => {
                setsuccess('')
                setCounter(30)
            }, 3000);

        }).catch(function (error) {
            setprogress(false)
            seterror(error || "There was an error try again later")
        });
    }

    return (
        <Modal
            open={true}
            onClose={() => ondismissmodals()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container sx={{ backgroundColor: 'white', mt: 3, borderRadius: 2 }} maxWidth="sm">
                {progress && <Loadingspinner />}
                {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
                {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
                <div>
                    <Stack mt={5} flexDirection={"row"} justifyContent={"space-between"} alignItems={'center'}>
                        <Box>
                            <Typography fontFamily={'boldfont'} color={'primary'} fontSize={'25px'} >Enter Code sent to</Typography>
                            <Typography>{useremail}</Typography>
                        </Box>

                        <IconButton onClick={() => ondismissmodals()}><Clear /></IconButton>
                    </Stack>
                    <Box component="form" onSubmit={validatecode} noValidate sx={{
                        marginTop: 8,
                        alignItems: "center"
                    }}>
                        <Stack flexDirection={"column"} alignItems={"center"} >
                            <VerificationInput
                                onChange={setcode}
                                length={6}
                            />
                        </Stack>
                        <Button
                            disabled={code?.length !== 6}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Validate Code
                        </Button>
                        <br />
                        {counter > 0 &&
                            <div style={{
                                paddingBottom: 10,
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: 20, display: "flex"
                            }}>
                                <div >Resending the code is available in</div>
                                <div style={{ color: primarycolor, marginLeft: 5 }}>{Math.floor(counter / 60)}:{(counter % 60).toString().padStart(2, '0')}</div>
                            </div>
                        }
                        {resendcode &&
                            <Button sx={{ marginBottom: 10 }} fullWidth variant='outlined' onClick={() => sendcode()}>Resend code</Button>
                        }

                    </Box>
                </div>
            </Container>
        </Modal>
    )
}

export default Verifyregistercode