import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder } from "../../../Utils/colors";
import CircularWithValueLabel from "../../../Components/circularprogress";
import { asessmentcandidates, assessmentsdummy, systemassessments } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import { AddCircleOutline, AlarmOn, ArrowDownward, BookmarkAdded, BorderColorOutlined, CheckCircle, Clear, DeleteRounded, ErrorOutline, KeyboardBackspace, NewReleases, ReportProblem, Search, Tune, Verified } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { convertTimestampToTime, navigateto } from "../../../Utils/functions";
import { Customcheckbox, Customsectiontextinput } from "../../../Components/textinputs.";
import Popupdialog from "../../../Components/popupcomponent";

const Clientassessments = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [selectedasessment, setselectedasessment] = useState('');

    const [hint, sethint] = useState('');



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Discover' }))
        }
    }, []);

    const handlehintchange = () => {

    }



    const gotoassessments = (assessment) => {
        navigateto("assessmentinfo")
        setselectedasessment(assessment)
    }




    return (
        <Box width={"100%"}>
            <div id="toppage" />
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography fontFamily={'boldfont'}>My Assessment</Typography>
            </Stack>
            <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Customsectiontextinput
                    fieldname={"Search"}
                    width={"70%"}

                    value={hint}
                    inputProps={{
                        endAdornment: (
                            <IconButton><Search /></IconButton>
                        ),
                    }}
                    placeholder={"Search for assessment"}
                    onChange={() => handlehintchange()}
                />
                <Button variant="outlined" endIcon={<Tune />}>Filter</Button>
                <Button onClick={() => navigate("/clientaccount/assessment-builder-1")} variant="contained" startIcon={<AddCircleOutline />} >New Assessment</Button>
            </Stack>
            <Stack mt={1}  >
                <Box width={"100%"} >
                    <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                        {assessmentsdummy?.map((assessment, key) => {
                            const progresspercentage = Math.round((parseInt(assessment.solved) / parseInt(assessment.quizes)) * 100);

                            return (
                                <Box onClick={() => gotoassessments(assessment)} position={"relative"} height={190} key={key} width={"23%"} margin={"1%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2, cursor: "pointer" }}>
                                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                        <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.name}</Typography>
                                        <CircularWithValueLabel value={progresspercentage} />
                                    </Stack>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {assessment.description}
                                    </Typography>
                                    <hr color="silver" />
                                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"}>
                                        <Box>
                                            <Typography fontSize={14}>Candidates</Typography>
                                            <Typography fontFamily={"boldfont"}>{assessment.solved}</Typography>
                                        </Box>
                                        <Box>
                                            <Typography fontSize={14}>Problems</Typography>
                                            <Typography fontFamily={"boldfont"}>{assessment.quizes}</Typography>
                                        </Box>
                                    </Stack>
                                    <Typography style={{
                                        position: "absolute",
                                        right: 2,
                                        bottom: 2
                                    }} fontFamily={"italiclightfont"} fontSize={12}>{convertTimestampToTime(assessment.created_at)}</Typography>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
            </Stack>
            <Box pt={4} width={"100%"} height={"90vh"} position={"relative"}>
                {selectedasessment && <Viewassessment goback={() => setselectedasessment('')} currentasessment={selectedasessment} />}
                <div style={{ position: "absolute", bottom: 0 }} id="assessmentinfo" />
            </Box>

        </Box>
    )
}

const Viewassessment = ({ currentasessment, goback }) => {

    const [displayedcandidates, setdisplayedcandidates] = useState(asessmentcandidates || []);
    const [selectedcandidates, setselectedcandidates] = useState([]);

    //booleans
    const [confirmshortlist, setconfirmshortlist] = useState(false);
    const [confirmdeletion, setconfirmdeletion] = useState(false);



    const handleShortlisting = () => {
        setconfirmshortlist(false)
    }

    const handleDeletion = () => {
        setconfirmdeletion(false)
    }


    const onselectall = () => {
        if (selectedcandidates.length === displayedcandidates.length) {
            setselectedcandidates([])
        } else {
            setselectedcandidates(displayedcandidates)
        }
    }


    //select or unselect candidate
    const checkcandidate = (isselected, candidate, candidatekey) => {
        if (isselected) {
            setselectedcandidates(selectedcandidates.filter((obj, key) => key !== candidatekey))
        } else {
            setselectedcandidates((prev) => [...prev, candidate])
        }
    }

    return (
        <Box>
            {confirmshortlist &&
                <Popupdialog
                    toptext={"Confirm shortlisting"}
                    question={"Are you sure you want to short list these candidates?"}
                    successtext={"Shortlist"}
                    oncancel={() => setconfirmshortlist(false)}
                    onsuccess={handleShortlisting}
                />
            }
            {confirmdeletion &&
                <Popupdialog
                    toptext={"Confirm Deletion!"}
                    question={"Are you sure you want to delete this assessment?"}
                    successtext={"Delete"}
                    oncancel={() => setconfirmdeletion(false)}
                    onsuccess={handleDeletion}
                />
            }
            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Stack flexDirection={"row"} alignItems={"center"}>
                    <KeyboardBackspace onClick={() => {
                        navigateto("toppage")
                        goback()
                    }} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                    <Typography fontFamily={'boldfont'}>{currentasessment?.name}</Typography>
                </Stack>
                <Stack flexDirection={"row"}>
                    <Button color="warning" title="close this asessment" endIcon={<Clear />} variant="text">Close </Button>&nbsp;
                    <Button onClick={() => setconfirmdeletion(true)} title="delete assessment" endIcon={<DeleteRounded />} color="error">Delete</Button>
                </Stack>
            </Stack>
            <Box mt={2} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Typography>
                    {currentasessment.description}
                </Typography>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Typography mt={2} fontSize={16} fontFamily={'boldfont'}>Matched Candidates</Typography>
                    <Stack>
                        <Button onClick={() => setconfirmshortlist(true)} disabled={selectedcandidates?.length < 1} endIcon={<BookmarkAdded />} sx={{ textTransform: "none" }}>ShortList</Button>
                    </Stack>
                </Stack>
                <Box mt={2}>
                    <Stack sx={muistyles.stackstyle} flexDirection={"row"}>
                        <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                            <Customcheckbox onChange={() => onselectall()} size={'small'} value={displayedcandidates.length === selectedcandidates.length} />
                            <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>Name</Typography>
                            <ArrowDownward fontSize="small" />
                        </Stack>
                        <Typography sx={muistyles.headtitle} />
                        <Typography sx={muistyles.headtitle} />
                        <Typography sx={muistyles.headtitle} />
                        <Typography sx={{ ...muistyles.headtitle, width: "10%" }} />
                    </Stack>

                    <Box mt={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                        {displayedcandidates.map((candidate, key) => {

                            const isselected = selectedcandidates.find((obj) => obj.id === candidate.id)

                            return (
                                <Stack sx={muistyles.contentstackstyle} flexDirection={"row"} key={key}>
                                    <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                                        <Customcheckbox onChange={() => checkcandidate(isselected, candidate, key)} size={'small'} value={isselected} />
                                        <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>{candidate.name}</Typography>
                                    </Stack>
                                    <Typography color={'primary'} sx={muistyles.contentheadtitle}>{candidate.match}% Match</Typography>
                                    <Typography sx={muistyles.contentheadtitle} >
                                        {candidate.completion ?
                                            <Stack flexDirection={"row"}><CheckCircle titleAccess="Completed Assessment" fontSize="small" color="success" />Done</Stack> :
                                            <Stack flexDirection={"row"}><ErrorOutline titleAccess="Pending Assessment" fontSize="small" color="error" /> Pending</Stack>
                                        }
                                    </Typography>
                                    <Typography sx={muistyles.contentheadtitle} >View Details</Typography>
                                    <Typography sx={{ ...muistyles.contentheadtitle, width: "10%" }} >{candidate.shortlisted && <BookmarkAdded fontSize="small" color="primary" titleAccess="shortlisted" />}</Typography>
                                </Stack>
                            )
                        })}
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}

export default Clientassessments