import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, IconButton, InputAdornment, Stack, Tooltip, Typography } from "@mui/material";
import { BookmarkOutlined, DataObject, ExpandLess, ExpandMore, KeyboardBackspace, LocationOn, LocationOnOutlined, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder, dimtextcolor } from "../../../Utils/colors";
import { Customcheckbox, Customreactselectinput, Customsectiontextinput } from "../../../Components/textinputs.";
import { experiencelevels } from "../../../Utils/arrays";
import countryList from 'react-select-country-list'
import { asessmentcandidates, dummymanimage } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import Nodatacomponent from "../../../Components/nodatacomponent";
import { Assessmentskeleton } from "../../../Components/skeletons";



const Discovercandidates = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const countryoptions = useMemo(() => countryList().getData(), [])
    const [loading, setloading] = useState(true);

    const [displayedcandidates, setdisplayedcandidates] = useState(asessmentcandidates || []);


    const [hint, sethint] = useState(Array.isArray(user?.prefferredskills) ? user?.prefferredskills[0].value : '');
    const [country, setcountry] = useState('');
    const [city, setcity] = useState('');

    //filters
    const [commitmenttype, setcommitmenttype] = useState('');
    const [experiencelevel, setexperiencelevel] = useState('');
    const [minexperience, setminexperience] = useState(0);
    const [maxexperience, setmaxexperience] = useState(200);


    const [showjobtypefilter, setshowjobtypefilter] = useState(true);
    const [showexperiencefilter, setshowexperiencefilter] = useState(true);



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Hire and Onboard' }))
        }
        setTimeout(() => {
            setloading(false)
        }, 3000);
    }, []);


    const commitmenttypes = [
        'Full-time', 'Part-time', 'Contract', "Internship"
    ]


    //change expericen level
    const onchangeexperience = (iscurrent, experience) => {
        if (iscurrent) {
            setexperiencelevel('')
            setminexperience(0)
            setmaxexperience(100)
        } else {
            setexperiencelevel(experience.label)
            setminexperience(experience.min)
            setmaxexperience(experience.max)
        }

    }

    //on change commitment type
    const onchangecommitment = (iscurrent, type) => {
        if (iscurrent) {
            setcommitmenttype('')
        } else {
            setcommitmenttype(type)
        }
    }


    //go to candidate profile
    const gotocandidateprofile = (candidate) => {

    }


    return (
        <Box>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Hire and Onboard</Typography>
            </Stack>
            <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"}>
                <Box mt={2} width={"30%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Filter</Typography>
                        <Button color="primary" variant="text" sx={{ fontSize: 13, fontFamily: 'boldfont' }}>Clear All</Button>
                    </Stack>
                    <hr />
                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Job Type</Typography>
                        <IconButton onClick={() => setshowjobtypefilter(!showjobtypefilter)}>
                            {showjobtypefilter ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Stack>
                    {showjobtypefilter &&
                        <Box>
                            {commitmenttypes.map((type, key) => {
                                const iscurrent = type === commitmenttype

                                return (
                                    <Stack mb={1} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Customcheckbox onChange={() => onchangecommitment(iscurrent, type)} value={iscurrent} />
                                        <Typography fontSize={14} ml={2}>{type}</Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    }

                    <Stack mt={1} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontFamily={"boldfont"} fontSize={13}>Experience</Typography>
                        <IconButton onClick={() => setshowexperiencefilter(!showexperiencefilter)}>
                            {showexperiencefilter ? <ExpandMore /> : <ExpandLess />}
                        </IconButton>
                    </Stack>
                    {showexperiencefilter &&
                        <Box>
                            {experiencelevels.map((experience, key) => {
                                const iscurrent = experience.label === experiencelevel

                                return (
                                    <Stack mb={1} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Customcheckbox onChange={() => onchangeexperience(iscurrent, experience)} value={iscurrent} />
                                        <Typography fontSize={14} ml={2}>{experience.label}</Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    }
                </Box>
                <Box mt={2} width={"68%"}  >
                    <Stack flexDirection={"row"} justifyContent={"space-between"}>
                        <Customsectiontextinput
                            width={"40%"}
                            value={hint}
                            onChange={sethint}
                            inputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Customreactselectinput
                            onChange={(e) => setcountry(e.label)}
                            width={"20%"}
                            options={countryoptions}
                        />
                        <Customsectiontextinput
                            placeholder={'city'}
                            width={"20%"}
                            value={city}
                            onChange={setcity}
                            inputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationOnOutlined />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button sx={{ height: 40 }} variant="contained">Search</Button>
                    </Stack>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        {displayedcandidates.length > 0 && !loading &&
                            <Stack flexDirection={"row"}>
                                <Typography fontSize={13} color={'dimtext'}>Showing </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{displayedcandidates.length} </Typography>
                                <Typography ml={1} fontSize={13}>Candidates </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{hint} </Typography>
                                <Typography ml={1} fontSize={13} >in </Typography>
                                <Typography ml={1} fontSize={13} fontFamily={"boldfont"}>{country || 'the platform'}</Typography>
                            </Stack>}
                        <DataObject sx={{ cursor: "pointer" }} titleAccess="Filter technology" color="primary" />
                    </Stack>
                    <Stack mt={1} flexDirection={"row"} width={"100%"} flexWrap={"wrap"} >
                        {!loading && displayedcandidates.length < 1 && <Nodatacomponent text={'No candidates match the search criteria'} />}
                        {loading && Array.from({ length: 10 }, (_, index) => (
                            <Assessmentskeleton key={index} />
                        ))}
                        {!loading && displayedcandidates.map((candidate, key) => {

                            return (
                                <Box position={"relative"} key={key} width={"30%"} margin={"1%"} padding={1} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                                    <Stack flexDirection={"row"}>
                                        <img src={dummymanimage} className="candidate-card-image" />
                                        <Box ml={2}>
                                            <Typography fontSize={14} fontFamily={'boldfont'}>{candidate.name}</Typography>
                                            <Typography sx={{ ...muistyles.twolinetextlimit, WebkitLineClamp: 1 }} fontSize={13} fontFamily={'boldfont'} color={dimtextcolor}>({candidate.speciality})</Typography>
                                        </Box>
                                    </Stack>
                                    <Tooltip title={candidate.description} placement="top">
                                        <Typography mt={1} mb={1} fontSize={13} sx={muistyles.twolinetextlimit}>
                                            {candidate.description}
                                        </Typography>
                                    </Tooltip>
                                    <Typography fontSize={13} fontFamily={"boldfont"} color={dimtextcolor}>{candidate.email}</Typography>
                                    <Typography fontSize={13} fontFamily={"boldfont"} color={dimtextcolor}>3 years Experience</Typography>
                                    <Stack mt={1} justifyContent={"space-between"} flexDirection={'row'}>
                                        <Button sx={{ height: 30, textTransform: "none" }} color="secondary" variant="outlined">Save</Button>
                                        <Button sx={{ height: 30, textTransform: "none" }} color="secondary" onClick={() => gotocandidateprofile(candidate)} variant="contained">View Profile</Button>
                                    </Stack>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}

export default Discovercandidates