
export const primarycolor = "#0073cf";
export const lightprimarycolor = "#5258fb"
export const secondarycolor = "#000"
export const columncolor = "rgb(231, 226, 226)";
export const columncoloralt = "#e0c2f3"
export const dimtextcolor = '#959593'
export const boxborder = '1px solid silver'



export const white = "#FFFFFF"