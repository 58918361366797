import React, { useEffect, useState } from "react"
import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/user";
import { AddOutlined, BorderColor, BorderColorOutlined, Clear, ContentCopy, HomeOutlined, LanguageOutlined, LocalPhoneOutlined, LocationOnOutlined, School, WorkOutline } from "@mui/icons-material";
import { assessmentsresults, dummymanimage, schoolsAttended } from "../../Utils/dummy";
import { copyToClipboard, formatDateRange, Greetings, openurl, returnperiod, returnsurname } from "../../Utils/functions";
import githubIcon from './../../../assets/svgs/github.svg';
import googleIcon from './../../../assets/svgs/google.svg';
import linkedinIcon from './../../../assets/svgs/linkedin.svg';
import { isMobile } from "react-device-detect";
import CircularWithValueLabel from "../../Components/circularprogress";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Addeducationmodal } from "./Modals/addeducationdetails";
import { useNavigate } from "react-router-dom";
import { boxborder, primarycolor } from "../../Utils/colors";
import { Successalert } from "../../Components/popups";
import muistyles from "../../Utils/muistyles";

const Candidatehome = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const [allskills, setallskills] = useState([]);

    const [success, setsuccess] = useState('');

    //modals
    const [showaddeducation, setshowaddeducation] = useState(false);

    const profilelink = `https://kontorva.com/profiles/${user?.name}`


    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Dashboard' }))
            //retrieve user skills
            const { frameworks, languages, databaseTechnologies, cloudTechnologies } = user
            setallskills([...frameworks, ...languages, ...databaseTechnologies, ...cloudTechnologies])

        }
    }, []);

    const copyprofilelink = () => {
        copyToClipboard(profilelink)
        setsuccess('Profile Link Copied successfully')
    }


    const svgsize = isMobile ? "15px" : '20px'

    return (
        <Box width={"100%"}>
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            {showaddeducation && <Addeducationmodal ondismiss={() => setshowaddeducation(false)} />}
            <Box padding={3} borderRadius={3} mb={2} sx={{ background: `linear-gradient(to right, ${primarycolor}, #57a2df)`, }}>
                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                    <Box>
                        <Typography fontSize={20} color={'white'} fontFamily={'boldfont'}>{Greetings()} {returnsurname(user?.name)}</Typography>
                        <Typography color={'white'}>Welcome to your candidate dashboard</Typography>
                        <Typography onClick={() => navigate("/candidateaccount/recommended-assessments")} mt={2} sx={{ color: 'white', textDecorationLine: 'underline', cursor: 'pointer' }} >Browse Recommended Assessments</Typography>
                    </Box>
                    <Button onClick={() => copyprofilelink()} sx={muistyles?.whitebutton} startIcon={<ContentCopy />} variant="contained">Share Public Profile</Button>
                </Stack>
            </Box>
            <Stack flexDirection={"row"} justifyContent={"space-between"} >
                <Box width={"30%"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Box height={150} width={"100%"} padding={2} sx={{ background: "linear-gradient(to right, #f8e7e7, #dcdff9)" }}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <img src={dummymanimage} className="homeprofileimage" />
                            <Box width={25} height={25} borderRadius={1} display={"flex"} alignItems={'center'} sx={{ backgroundColor: "white" }} justifyContent={"center"}>
                                <IconButton size='small'><BorderColor fontSize="small" /></IconButton>
                            </Box>
                        </Stack>
                    </Box>
                    <Box padding={2}>
                        <Typography fontFamily={'boldfont'} fontSize={17}>{user?.name}</Typography>
                        <Typography fontSize={14}>{user?.email}</Typography>
                        <Box mt={2} mb={2}>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <WorkOutline fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{user?.role}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <LocationOnOutlined fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{user?.city}, {user?.country}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <LanguageOutlined fontSize="small" color="gray" />
                                <Typography
                                    color={'gray'}
                                    fontSize={14}
                                    ml={1}
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%'
                                    }}
                                >
                                    {user?.linkedinlink}</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems={"center"} mb={1}>
                                <LocalPhoneOutlined fontSize="small" color="gray" /> <Typography color={'gray'} fontSize={14} ml={1}>{user?.phone || '2547 22 222 222'}</Typography>
                            </Stack>
                        </Box>
                        <hr />
                        <Box mt={1} mb={2}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography fontSize={14} fontFamily={'boldfont'}>More Details</Typography>
                                <Button variant="text" color="primary">Edit</Button>
                            </Stack>
                            <Box >
                                <Typography fontSize={13}>Current</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>Github</Typography>
                                <Typography mt={1} fontSize={13}>Years of Experience</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{returnperiod(user?.professionalexperience)}</Typography>
                                <Typography mt={1} fontSize={13}>Freelance</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{returnperiod(user?.freelanceexperience)}</Typography>
                                <Typography mt={1} fontSize={13}>English proficiency</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{user?.englishlevel}</Typography>
                                <Typography mt={1} fontSize={13}>Commitment Type</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{user?.commitmenttype}</Typography>
                                <Typography mt={1} fontSize={13}>Expected Pay</Typography>
                                <Typography fontSize={14} fontFamily={'boldfont'}>{user?.expectedpay} $/month</Typography>
                            </Box>
                        </Box>
                        <hr />
                        <Box mt={1} mb={2}>
                            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                <Typography fontSize={14} fontFamily={'boldfont'}>Social Accounts</Typography>
                                <Button variant="text" color="primary">Edit</Button>
                            </Stack>
                            <Stack onClick={() => openurl(user?.linkedinlink)} mt={1} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                <img src={linkedinIcon} style={{ height: svgsize, width: svgsize }} />
                                <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Linkedin</Typography>
                            </Stack>
                            <Stack onClick={() => openurl(user?.githublink)} mt={2} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                <img src={githubIcon} style={{ height: svgsize, width: svgsize }} />
                                <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Github</Typography>
                            </Stack>
                            {user?.customlink &&
                                <Stack onClick={() => openurl(user.customlink)} mt={2} sx={{ cursor: "pointer" }} flexDirection={"row"} alignItems={"center"}>
                                    <img src={googleIcon} style={{ height: svgsize, width: svgsize }} />
                                    <Typography fontFamily={'boldfont'} fontSize={14} ml={2}>Website</Typography>
                                </Stack>
                            }
                        </Box>
                    </Box>
                </Box>
                <Box width={"68%"} >
                    <Box sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <CircularWithValueLabel value={68} />
                            <Box ml={2}>
                                <Typography fontFamily={'boldfont'} fontSize={13}>Complete Your Skills Assessments</Typography>
                                <Typography fontSize={12}>
                                    Complete the assessments related to your skills profile to
                                    be active and discoverable to recruiters looking for you
                                </Typography>
                            </Box>
                            <Button onClick={() => navigate("/candidateaccount/candidateassessments")} sx={{ height: 30, fontSize: 13 }} startIcon={<BorderColorOutlined />} variant="outlined" color="secondary">Complete</Button>
                        </Stack>
                    </Box>
                    <Stack mt={2} mb={2} flexDirection={"row"} justifyContent={"space-between"}>
                        <Stack width={"29%"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/receive.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Points Collected</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>245</Typography>
                            </Box>
                        </Stack>
                        <Stack width={"29%"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/rated.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Ratings</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>4.5</Typography>
                            </Box>
                        </Stack>
                        <Stack width={"29%"} alignItems={"center"} flexDirection={"row"} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                            <img style={{ maxWidth: 50 }} src={require('./../../../assets/icons/completed.png')} />
                            <Box ml={1}>
                                <Typography fontSize={13}>Assessment Taken</Typography>
                                <Typography fontSize={16} fontFamily={'boldfont'}>158</Typography>
                            </Box>
                        </Stack>
                    </Stack>
                    <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Typography mb={1} mt={1} fontFamily={'boldfont'}>Average Ratings</Typography>
                        <ResponsiveContainer width="95%" height={300}>
                            <BarChart
                                data={assessmentsresults}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="skill" />
                                <YAxis />
                                {/* <Tooltip /> */}
                                <Bar dataKey="score" radius={[10, 10, 10, 10]} barSize={50} fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                    <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Box>
                                <Typography mb={1} mt={1} fontFamily={'boldfont'}>Verified Skills</Typography>
                                <Typography fontSize={13}>This will help us understand your strengths and match you with the right opportunities</Typography>
                            </Box>
                            <Button sx={{ height: 30, fontSize: 13 }} variant="outlined" color="secondary" startIcon={<AddOutlined />}>Add New</Button>
                        </Stack>
                        <Box display={"flex"} flexWrap={"wrap"} mt={1} mb={2}>
                            {allskills.map((skill, key) => {

                                return (
                                    <Stack key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Typography fontSize={14} mr={1}>{skill?.label}</Typography>
                                        <IconButton><Clear fontSize="small" /></IconButton>
                                    </Stack>
                                )
                            })}
                        </Box>
                    </Box>
                    <Box width={"100%"} mt={2} mb={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }} padding={1}>
                        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography mb={1} mt={1} fontFamily={'boldfont'}>Education</Typography>
                            <Button onClick={() => setshowaddeducation(true)} sx={{ height: 30, fontSize: 13 }} variant="outlined" color="secondary" startIcon={<AddOutlined />}>Add New</Button>
                        </Stack>
                        <Box mt={1}>
                            {schoolsAttended.map((school, key) => {
                                return (
                                    <Stack mb={2} key={key} flexDirection={"row"} alignItems={"center"}>
                                        <Box width={40} height={40} border={boxborder} borderRadius={1} display={"flex"} alignItems={'center'} justifyContent={"center"}>
                                            <IconButton><School fontSize="medium" /></IconButton>
                                        </Box>
                                        <Box ml={1}>
                                            <Typography fontSize={14} fontFamily={"boldfont"}>{school.school}</Typography>
                                            <Typography fontSize={13}>
                                                {school.program} {school.degree} - {formatDateRange(school.startdate, school.enddate)}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                )
                            })}

                        </Box>

                    </Box>
                </Box>
            </Stack>

        </Box>
    )
}

export default Candidatehome