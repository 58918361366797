import React, { useEffect, useMemo, useRef, useState } from "react"
import { Box, Button, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Clear, DriveFileRenameOutline, East, KeyboardBackspace, LocationOnOutlined, Search, Tune } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import muistyles from "../../../../Utils/muistyles";
import { Customsectiontextinput } from "../../../../Components/textinputs.";
import { setNewassessment } from "../../../../../store/newassessment";




const Assessmentbuilder4 = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const { newassessment } = useSelector((state) => state.newassessment);
    const dispatch = useDispatch()

    const defaultinstruction = { instruction: "" };
    const defaultintructions = Array.from({ length: 3 }, () => ({ ...defaultinstruction }));

    const [instructions, setinstructions] = useState(newassessment?.instructions || defaultintructions);

    const inputRefs = useRef([]);

    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
    }, []);

    ///auto add instructions
    useEffect(() => {
        const arraylength = instructions.length
        const checkfilledoptions = instructions.filter((obj) => obj.instruction);
        if (checkfilledoptions.length + 1 >= arraylength) {
            setinstructions([...instructions, defaultinstruction])
        }
    }, [instructions]);




    //move through the form
    const instructiontextkeydown = (event, index) => {
        if (event.key === "Enter") {
            event.preventDefault();
            if (index < instructions.length - 1) {
                // If Enter key is pressed and not the last instruction
                inputRefs.current[index + 1].focus(); // Focus on the next instruction input
            }
        } else if (event.key === "Backspace" && index > 0 && instructions[index].instruction === "") {
            event.preventDefault();
            inputRefs.current[index - 1].focus(); // Focus on the previous instruction input
        }
    }

    //on change instruction text 
    const onchangeinstructiontext = (text, key) => {
        const clonedoptions = [...instructions]
        clonedoptions[key].instruction = text
        setinstructions(clonedoptions)
    }



    //remove instruction
    const removeinstruction = (optionkey) => {
        setinstructions(instructions.filter((obj, key) => key !== optionkey))
    }



    const gotonext = () => {
        const finalinstructions = instructions.filter((obj) => obj.instruction !== '')
        const updatedassessment = { ...newassessment, instructions: finalinstructions }

        dispatch(setNewassessment(updatedassessment))
        navigate("/clientaccount/assessment-builder-5")
    }



    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Instructions</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={3} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} padding={4} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                {newassessment &&
                    <Box mb={2} sx={{ height: "60vh", overflowY: "scroll" }}>
                        <Typography>Add test Instructions</Typography>
                        <Box>
                            {instructions.map((instruction, key) => {

                                return (
                                    <Stack width={"90%"} key={key} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Customsectiontextinput
                                            inputRef={(ref) => (inputRefs.current[key] = ref)}
                                            fieldname={"Instruction " + (key + 1)}
                                            handleKeyDown={(e) => instructiontextkeydown(e, key)}
                                            value={instruction.instruction}
                                            multiline={true}
                                            rows={2}
                                            onChange={(text) => onchangeinstructiontext(text, key)}
                                            width={"90%"}
                                        />
                                        <Clear sx={{ cursor: "pointer" }} onClick={() => removeinstruction(key)} color="error" />
                                    </Stack>
                                )
                            })}
                        </Box>


                    </Box>}
                <Stack flexDirection={"row"} justifyContent={"space-between"} >
                    <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                    <Button disabled={(instructions.filter((obj) => obj.instruction)).length < 1} onClick={gotonext} sx={buttonstyle} endIcon={<East />} variant="contained">Next</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Assessmentbuilder4