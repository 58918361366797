import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, IconButton, InputAdornment, Stack, Step, StepLabel, Stepper, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { East, KeyboardBackspace, LocationOnOutlined, Search, Tune } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import { Customsectiontextinput } from "../../../../Components/textinputs.";
import { systemassessments } from "../../../../Utils/dummy";
import muistyles from "../../../../Utils/muistyles";
import { setNewassessment } from "../../../../../store/newassessment";



const Asssessmentbuilder2 = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()
    const { newassessment } = useSelector((state) => state.newassessment);

    const [displayedassessments, setdisplayedassessments] = useState(systemassessments || []);
    const [selectedasessments, setselectedasessments] = useState(newassessment?.selectedasessments || []);


    const [hint, sethint] = useState('');



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
    }, []);



    const handlehintchange = (temphint) => {
        sethint(temphint)

    }


    const onselectassessment = (assessment, isselected) => {
        if (isselected) {
            setselectedasessments(selectedasessments.filter((obj) => obj.id !== assessment.id))
        } else {
            setselectedasessments([...selectedasessments, assessment])
        }
    }


    const gotonext = () => {
        const updatedassessment = { ...newassessment, selectedasessments }
        dispatch(setNewassessment(updatedassessment))
        navigate("/clientaccount/assessment-builder-3")
    }




    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Choose Tests</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={1} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} padding={4} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Stack justifyContent={"space-between"} flexDirection={"row"} alignItems={"center"} padding={2}>
                    <Customsectiontextinput
                        fieldname={"Search"}
                        width={"70%"}
                        value={hint}
                        inputProps={{
                            endAdornment: (
                                <IconButton><Search /></IconButton>
                            ),
                        }}
                        placeholder={"Search for assessment"}
                        onChange={() => handlehintchange()}
                    />
                    <Button variant="outlined" endIcon={<Tune />}>Filter</Button>
                    <Button variant="contained"  >Search</Button>
                </Stack>
                <Typography mb={2} fontSize={13} color={'primary'}>{selectedasessments.length} Selected</Typography>
                <Stack flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
                    {displayedassessments.slice(0, 6).map((assessment, key) => {
                        const isselected = (selectedasessments.findIndex((obj) => obj.id === assessment.id)) > -1

                        return (
                            <Box onClick={() => onselectassessment(assessment, isselected)} mb={2} key={key} width={"30%"} padding={2} sx={{ backgroundColor: 'white', border: isselected ? '2px solid blue' : boxborder, borderRadius: 2, cursor: "pointer" }}>
                                <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                    <Typography sx={muistyles.twolinetextlimit} fontFamily={"boldfont"}>{assessment.name}</Typography>
                                </Stack>
                                <Tooltip title={assessment.description}>
                                    <Typography mb={1} fontSize={14} sx={muistyles.twolinetextlimit}>
                                        {assessment.description}
                                    </Typography>
                                </Tooltip>
                                <Box borderRadius={3} border={"1px solid orange"} sx={{ backgroundColor: "#fcf1e9", padding: "3px 6px", display: "inline-block" }}>
                                    <Typography fontFamily={'boldfont'} fontSize={12} color={'#e59447'}>{assessment.duration}</Typography>
                                </Box>
                            </Box>
                        )
                    })}

                </Stack>
                <Stack flexDirection={"row"} justifyContent={"space-between"} >
                    <Button onClick={() => navigate(-1)} sx={buttonstyle} variant="outlined">Back</Button>
                    <Button onClick={gotonext} sx={buttonstyle} endIcon={<East />} variant="contained">Next</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Asssessmentbuilder2