import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, InputAdornment, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { East, KeyboardBackspace, LocationOnOutlined } from "@mui/icons-material";
import { setUser } from "../../../../../store/user";
import { assessmentbuildersteps, commitmenttypes } from "../../../../Utils/arrays";
import { boxborder } from "../../../../Utils/colors";
import { Customreactselectinput, Customsectiontextinput, Customselectinput } from "../../../../Components/textinputs.";
import countryList from 'react-select-country-list'
import { setNewassessment } from "../../../../../store/newassessment";


const Asssessmentbuilder1 = () => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const { newassessment } = useSelector((state) => state.newassessment);

    const dispatch = useDispatch()
    const countryoptions = useMemo(() => countryList().getData(), [])

    const [role, setrole] = useState(newassessment?.role || '');
    const [country, setcountry] = useState(newassessment?.country || '');
    const [city, setcity] = useState(newassessment?.city || '');
    const [commitmenttype, setcommitmenttype] = useState(newassessment?.commitmenttype || '');



    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Assessment Builder' }))
        }
    }, []);


    const gotonext = () => {
        let updatedassessment = { role, country, city, commitmenttype }
        if (newassessment) {
            updatedassessment = { ...newassessment, updatedassessment }
        }

        dispatch(setNewassessment(updatedassessment))
        navigate("/clientaccount/assessment-builder-2")
    }



    const buttonstyle = { borderRadius: 10, height: 50, width: 100, fontFamily: "boldfont" }
    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Role Details</Typography>
            </Stack>
            <Box mt={1}>
                <Stepper activeStep={0} alternativeLabel>
                    {assessmentbuildersteps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Box mt={2} padding={6} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                <Stack flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
                    <Customsectiontextinput
                        fieldname={"Role/Position"}
                        hint={'i.e React Developer'}
                        value={role}
                        onChange={setrole}
                        width={"48%"}
                    />
                    <Customreactselectinput
                        fieldname={"Country"}
                        hint={'i.e Kenya'}
                        onChange={(e) => setcountry(e.label)}
                        width={"48%"}
                        options={countryoptions}
                    />
                    <Customsectiontextinput
                        fieldname={'City'}
                        hint={'Nairobi'}
                        placeholder={'city'}
                        width={"48%"}
                        value={city}
                        onChange={setcity}
                        inputProps={{
                            startAdornment: (
                                <InputAdornment position="end">
                                    <LocationOnOutlined />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Customselectinput
                        fieldname={"Commitment Type"}
                        hint={'i.e Full-time'}
                        value={commitmenttype}
                        onChange={setcommitmenttype}
                        width={"48%"}
                        options={commitmenttypes}
                    />
                </Stack>
                <Stack flexDirection={"row"} justifyContent={"right"}>
                    <Button onClick={gotonext} disabled={!role} sx={buttonstyle} endIcon={<East />} variant="contained">Next</Button>
                </Stack>
            </Box>

        </Box >
    )
}

export default Asssessmentbuilder1