import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import App from './App';
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./store"
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import theme from './pages/Utils/theme';
import "./../src/pages/Utils/styles.css"

// ScrollToTop component
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <ScrollToTop />
          <div className='mainbody'>
            <App />
          </div>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  </Provider>
);
