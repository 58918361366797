//redux constants
export const reduxconstants = {
  USER_PROFILE: "user",
  USER_PROFILES: "profiles",
  NEW_ASSESSMENT: "newassessment"
}


//developer roles
export const developerroles = [
  'Frontend', 'Backend', 'Fullstack (BE-Heavy)', 'Fullstack (FE-Heavy)',
  'Fullstack (Balanced)', 'Design', 'Mobile', 'QA', 'DevOps', 'Data Science',
  'Data Engineering', 'Machine Learning', 'Game Development', 'Business Intelligence',
  'Embeded Engineer', 'Other'
]


export const programmingLanguagesFrameworks = [
  { label: "React js", value: "react js" },
  { label: "Vue js", value: "vue js" },
  { label: "Angular", value: "angular" },
  { label: "Svelte", value: "svelte" },
  { label: "Next js", value: "next js" },
  { label: "Nuxt js", value: "nuxt js" },
  { label: "Gatsby", value: "gatsby" },
  { label: "Ember js", value: "ember js" },
  { label: "Meteor js", value: "meteor js" },
  { label: "Express js", value: "express js" },
  { label: "NestJS", value: "nestjs" },
  { label: "Django", value: "django" },
  { label: "Flask", value: "flask" },
  { label: "Spring Boot", value: "spring-boot" },
  { label: "Ruby on Rails", value: "ruby-on-rails" },
  { label: "Laravel", value: "laravel" },
  { label: "ASP.NET Core", value: "asp.net-core" },
  { label: "Symfony", value: "symfony" },
  { label: "Phoenix (Elixir)", value: "phoenix-elixir" },
  { label: "Koa js", value: "koa js" },
  { label: "FastAPI", value: "fastapi" },
  { label: "Micronaut", value: "micronaut" },
  { label: "React Native", value: "react-native" },
  { label: "Flutter", value: "flutter" },
  { label: "Ionic", value: "ionic" },
  { label: "Xamarin", value: "xamarin" },
  { label: "Cordova", value: "cordova" },
  { label: "Unity", value: "unity" },
  { label: "Unreal Engine", value: "unreal-engine" },
  { label: "Godot", value: "godot" },
  { label: "Cocos2d", value: "cocos2d" },
  { label: "CryEngine", value: "cryengine" },
  { label: "TensorFlow", value: "tensorflow" },
  { label: "PyTorch", value: "pytorch" },
  { label: "Keras", value: "keras" },
  { label: "Pandas", value: "pandas" },
  { label: "NumPy", value: "numpy" },
  { label: "Scikit-learn", value: "scikit-learn" },
  { label: "Hugging Face Transformers", value: "hugging-face-transformers" },
  { label: "Electron", value: "electron" },
  { label: "Qt", value: "qt" },
  { label: "Tkinter", value: "tkinter" },
  { label: "jQuery", value: "jquery" },
  { label: "Bootstrap", value: "bootstrap" },
  { label: "Tailwind CSS", value: "tailwind-css" },
  { label: "Chakra UI", value: "chakra-ui" },
];



//programming languages
export const programminglanguagesarray = [
  { label: "JavaScript", value: "javascript" },
  { label: "Python", value: "python" },
  { label: "Java", value: "java" },
  { label: "C++", value: "cpp" },
  { label: "C#", value: "csharp" },
  { label: "Ruby", value: "ruby" },
  { label: "PHP", value: "php" },
  { label: "Swift", value: "swift" },
  { label: "Kotlin", value: "kotlin" },
  { label: "Go", value: "go" },
  { label: "Rust", value: "rust" },
  { label: "TypeScript", value: "typescript" },
  { label: "Perl", value: "perl" },
  { label: "Objective-C", value: "objective-c" },
  { label: "Scala", value: "scala" },
  { label: "R", value: "r" },
  { label: "Dart", value: "dart" },
  { label: "Elixir", value: "elixir" },
  { label: "Haskell", value: "haskell" },
];

//database technologies  
export const databaseTechnologiesArray = [
  { label: 'Firebase', value: 'firebase' },
  { label: "MySQL", value: "mysql" },
  { label: "PostgreSQL", value: "postgresql" },
  { label: "MongoDB", value: "mongodb" },
  { label: "SQLite", value: "sqlite" },
  { label: "Oracle", value: "oracle" },
  { label: "Microsoft SQL Server", value: "mssql" },
  { label: "MariaDB", value: "mariadb" },
  { label: "Redis", value: "redis" },
  { label: "Cassandra", value: "cassandra" },
  { label: "Elasticsearch", value: "elasticsearch" },
  { label: "Firebase", value: "firebase" },
  { label: "CouchDB", value: "couchdb" },
  { label: "Neo4j", value: "neo4j" },
  { label: "Amazon DynamoDB", value: "dynamodb" },
  { label: "HBase", value: "hbase" },
  { label: "CockroachDB", value: "cockroachdb" },
  { label: "Realm", value: "realm" },
  { label: "ArangoDB", value: "arangodb" },
  { label: "Teradata", value: "teradata" },
];


export const cloudPlatformsArray = [
  { label: "Amazon Web Services (AWS)", value: "aws" },
  { label: "Microsoft Azure", value: "azure" },
  { label: "Google Cloud Platform (GCP)", value: "gcp" },
  { label: "IBM Cloud", value: "ibm-cloud" },
  { label: "Alibaba Cloud", value: "alibaba-cloud" },
  { label: "Oracle Cloud", value: "oracle-cloud" },
  { label: "Salesforce Cloud", value: "salesforce-cloud" },
  { label: "DigitalOcean", value: "digitalocean" },
  { label: "Heroku", value: "heroku" },
  { label: "Linode", value: "linode" },
  { label: "Vercel", value: "vercel" },
  { label: "Netlify", value: "netlify" },
  { label: "Docker Cloud", value: "docker-cloud" },
  { label: "Red Hat OpenShift", value: "openshift" },
  { label: "Cloudflare", value: "cloudflare" },
  { label: "Tencent Cloud", value: "tencent-cloud" },
  { label: "Raspberry Pi Cloud", value: "raspberry-pi-cloud" },
  { label: "Zoho Cloud", value: "zoho-cloud" },
  { label: "Kubernetes Engine (GKE)", value: "gke" },
];



//skill levels 
export const skilllevels = [
  {
    label: 'Basic',
    description: 'Foundational understanding; can perform simple tasks with guidance.'
  },
  {
    label: 'Intermediate',
    description: 'Can work independently on standard tasks; good understanding with occasional need for help.'
  },
  {
    label: 'Advanced',
    description: 'In-depth knowledge; handles complex tasks and guides others with rare need for assistance.'
  },
  {
    label: 'Expert',
    description: 'Highly proficient; manages any task, innovates, and mentors others.'
  }
];

export const englishLevels = [
  {
    label: 'Basic',
    description: 'Can understand and use simple phrases; communicates in basic situations.'
  },
  {
    label: 'Intermediate',
    description: 'Can handle most situations while traveling; has a good grasp of grammar and vocabulary for everyday conversations.'
  },
  {
    label: 'Advanced',
    description: 'Can understand and discuss complex topics; speaks fluently and naturally in both professional and social settings.'
  },
  {
    label: 'Expert',
    description: 'Has near-native proficiency; communicates effortlessly and precisely, with excellent command of nuance and idiomatic expressions.'
  }
];



//it degrees
export const ITDegrees = [
  { label: "Bachelor of Science in Computer Science", value: "bsc_computer_science" },
  { label: "Bachelor of Science in Information Technology", value: "bsc_information_technology" },
  { label: "Bachelor of Science in Software Engineering", value: "bsc_software_engineering" },
  { label: "Bachelor of Science in Cybersecurity", value: "bsc_cybersecurity" },
  { label: "Bachelor of Science in Data Science", value: "bsc_data_science" },
  { label: "Bachelor of Science in Artificial Intelligence", value: "bsc_artificial_intelligence" },
  { label: "Bachelor of Science in Information Systems", value: "bsc_information_systems" },
  { label: "Bachelor of Science in Network Engineering", value: "bsc_network_engineering" },
  { label: "Bachelor of Science in Web Development", value: "bsc_web_development" },
  { label: "Bachelor of Science in Computer Engineering", value: "bsc_computer_engineering" },
  { label: "Master of Science in Information Technology", value: "msc_information_technology" },
  { label: "Master of Science in Data Analytics", value: "msc_data_analytics" },
  { label: "Master of Science in Cybersecurity", value: "msc_cybersecurity" },
  { label: "Master of Science in Software Engineering", value: "msc_software_engineering" },
  { label: "Master of Science in Computer Science", value: "msc_computer_science" }
];
export const ITPrograms = [
  { label: "Certificate", value: "certificate" },
  { label: "Diploma", value: "diploma" },
  { label: "Associate Degree", value: "associate_degree" },
  { label: "Bachelor's Degree", value: "bachelors_degree" },
  { label: "Master's Degree", value: "masters_degree" },
  { label: "PhD", value: "phd" },
  { label: "Professional Certification", value: "professional_certification" },
  { label: "Short Course", value: "short_course" }
];

export const alphabets = ['A', 'B', 'C', 'D', 'E', 'F']

//code editor themes
export const editorthemes = ["light", "vs-dark"]


// Code editor languages supported with IDs and sample code
export const editorsupportedlanguages = [
  { label: "JavaScript", value: "javascript", id: 63, sampleCode: "console.log(`the sum of 30 + 40=${30+40}`);" },
  { label: "TypeScript", value: "typescript", id: 74, sampleCode: "console.log('Hello, TypeScript!');" },
  { label: "CSS", value: "css", id: 0, sampleCode: "body { background-color: lightblue; }" },
  { label: "HTML", value: "html", id: 0, sampleCode: "<!DOCTYPE html><html><body><h1>Hello, World!</h1></body></html>" },
  { label: "JSON", value: "json", id: 0, sampleCode: "{ \"message\": \"Hello, World!\" }" },
  { label: "XML", value: "xml", id: 0, sampleCode: "<message>Hello, World!</message>" },
  { label: "Markdown", value: "markdown", id: 0, sampleCode: "# Hello, World!" },
  { label: "YAML", value: "yaml", id: 0, sampleCode: "message: Hello, World!" },
  { label: "SQL", value: "sql", id: 82, sampleCode: "SELECT 'Hello, World!';" },
  { label: "Java", value: "java", id: 62, sampleCode: "public class HelloWorld { public static void main(String[] args) { System.out.println('Hello, World!'); } }" },
  { label: "Python", value: "python", id: 71, sampleCode: "print('Hello, World!')" },
  { label: "C++", value: "cpp", id: 76, sampleCode: "#include <iostream>\nint main() { std::cout << 'Hello, World!' << std::endl; return 0; }" },
  { label: "C#", value: "csharp", id: 51, sampleCode: "using System;\nclass Program { static void Main() { Console.WriteLine('Hello, World!'); } }" },
  { label: "PHP", value: "php", id: 68, sampleCode: "<?php echo 'Hello, World!'; ?>" },
  { label: "Go", value: "go", id: 60, sampleCode: "package main\nimport \"fmt\"\nfunc main() { fmt.Println('Hello, World!') }" },
  { label: "Ruby", value: "ruby", id: 72, sampleCode: "puts 'Hello, World!'" },
  { label: "Swift", value: "swift", id: 83, sampleCode: "print('Hello, World!')" },
  { label: "Razor", value: "razor", id: 0, sampleCode: "@{ var message = \"Hello, World!\"; }\n<p>@message</p>" },
  { label: "Objective-C", value: "objective-c", id: 79, sampleCode: "#import <Foundation/Foundation.h>\nint main() {\n    NSLog(@'Hello, World!');\n    return 0;\n}" },
  { label: "Pug", value: "pug", id: 0, sampleCode: "html\n  head\n    title Hello\n  body\n    h1 Hello, World!" },
  { label: "Dockerfile", value: "dockerfile", id: 0, sampleCode: "FROM alpine\nRUN echo 'Hello, World!'" },
  { label: "PowerShell", value: "powershell", id: 0, sampleCode: "Write-Output 'Hello, World!'" },
  { label: "Shell Script", value: "shell", id: 46, sampleCode: "echo 'Hello, World!'" },
  { label: "Less", value: "less", id: 0, sampleCode: "@color: #4D926F;\n#header { color: @color; }" },
  { label: "SCSS", value: "scss", id: 0, sampleCode: "$color: #4D926F;\n#header { color: $color; }" }
];


// { id: 45, name: 'Assembly (NASM 2.14.02)' }
// { id: 46, name: 'Bash (5.0.0)' }
// { id: 47, name: 'Basic (FBC 1.07.1)' }
// { id: 75, name: 'C (Clang 7.0.1)' }
// { id: 76, name: 'C++ (Clang 7.0.1)' }
// { id: 48, name: 'C (GCC 7.4.0)' }
// { id: 52, name: 'C++ (GCC 7.4.0)' }
// { id: 49, name: 'C (GCC 8.3.0)' }
// { id: 53, name: 'C++ (GCC 8.3.0)' }
// { id: 50, name: 'C (GCC 9.2.0)' }
// { id: 54, name: 'C++ (GCC 9.2.0)' }
// { id: 86, name: 'Clojure (1.10.1)' }
// { id: 51, name: 'C# (Mono 6.6.0.161)' }
// { id: 77, name: 'COBOL (GnuCOBOL 2.2)' }
// { id: 55, name: 'Common Lisp (SBCL 2.0.0)' }
// { id: 56, name: 'D (DMD 2.089.1)' }
// { id: 57, name: 'Elixir (1.9.4)' }
// { id: 58, name: 'Erlang (OTP 22.2)' }
// { id: 44, name: 'Executable' }
// { id: 87, name: 'F# (.NET Core SDK 3.1.202)' }
// { id: 59, name: 'Fortran (GFortran 9.2.0)' }
// { id: 60, name: 'Go (1.13.5)' }
// { id: 88, name: 'Groovy (3.0.3)' }
// { id: 61, name: 'Haskell (GHC 8.8.1)' }
// { id: 62, name: 'Java (OpenJDK 13.0.1)' }
// { id: 63, name: 'JavaScript (Node.js 12.14.0)' }
// { id: 78, name: 'Kotlin (1.3.70)' }
// { id: 64, name: 'Lua (5.3.5)' }
// { id: 89, name: 'Multi-file program' }
// { id: 79, name: 'Objective-C (Clang 7.0.1)' }
// { id: 65, name: 'OCaml (4.09.0)' }
// { id: 66, name: 'Octave (5.1.0)' }
// { id: 67, name: 'Pascal (FPC 3.0.4)' }
// { id: 85, name: 'Perl (5.28.1)' }
// { id: 68, name: 'PHP (7.4.1)' }
// { id: 43, name: 'Plain Text' }
// { id: 69, name: 'Prolog (GNU Prolog 1.4.5)' }
// { id: 70, name: 'Python (2.7.17)' }
// { id: 71, name: 'Python (3.8.1)' }
// { id: 80, name: 'R (4.0.0)' }
// { id: 72, name: 'Ruby (2.7.0)' }
// { id: 73, name: 'Rust (1.40.0)' }
// { id: 81, name: 'Scala (2.13.2)' }
// { id: 82, name: 'SQL (SQLite 3.27.2)' }
// { id: 83, name: 'Swift (5.2.3)' }
// { id: 74, name: 'TypeScript (3.7.4)' }
// { id: 84, name: 'Visual Basic.Net (vbnc 0.0.0.5943)' }




export const feedbackaffectedFeatures = [
  "Developer Profile Visibility",
  "Coding Challenge Assessments",
  "Certification System",
  "Custom Test Administration for Clients",
  "Developer Search & Filtering",
  "Developer Certification Display",
  "Client-Developer Communication",
  "Assessment Result Reporting",
  "Test Feedback and Review System",
  "Notifications & Alerts for Custom Tests",
  "Client Dashboard for Developer Selection",
  "Developer Skill Categorization",
  "Performance Analytics for Developers",
  "Platform Onboarding & Guidance"
];

export const companysizes = ["2-10 Employees", "11-50 Employees", "51-200 Employees"]

export const companyTypes = [
  "Information Technology (IT)",
  "Logistics",
  "Healthcare",
  "Manufacturing",
  "Retail",
  "Construction",
  "Finance",
  "Education",
  "Agriculture",
  "Telecommunications",
  "Hospitality",
  "Real Estate",
  "Energy",
  "Transportation",
  "Consulting",
  "Media and Entertainment",
  "Pharmaceuticals",
  "Aerospace and Defense",
  "Automotive",
  "E-commerce",
  "Food and Beverage",
  "Insurance",
  "Biotechnology",
  "Legal Services",
  "Mining"
];



export const experiencelevels = [
  {
    label: "Less than a Year",
    min: 0,
    max: 12
  },
  {
    label: "1-3 Years",
    min: 12,
    max: 36
  },
  {
    label: "2-5 Years",
    min: 24,
    max: 60
  },
  {
    label: "5-10 Years",
    min: 60,
    max: 120
  }
];


//assessment builder stepa
export const assessmentbuildersteps = [
  "Role Details", "Choose Tests", "Custom Questions", "Instructions", "Finalize", 'Invite Candidates'
]

export const commitmenttypes = [
  'Full-time', 'Part-time', 'Hourly', "I'm not really sure"
]

//question types
export const Questiontypes = [
  "Multiple Choices", "Open", "Coding"
]