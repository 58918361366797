import { Box, Button, Checkbox, CircularProgress, Container, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Customreactselectinput, Customsectiontextinput } from "../Components/textinputs.";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Sitelogo from "./sitelogo";
import Popupdialog from "../Components/popupcomponent";
import Loadingspinner from "../Components/loading";
import { Erroralert, Successalert } from "../Components/popups";
import useAuthAxios from "../../hooks/useAuthAxios";
import { Fetchaccountprofileurl, Loginuserurl, Registeruserurl } from "../Utils/urls";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/user";
import { primarycolor } from "../Utils/colors";
import googleIcon from './../../assets/svgs/google.svg';
import githubIcon from './../../assets/svgs/github.svg';
import linkedinIcon from './../../assets/svgs/linkedin.svg';
import facebookIcon from './../../assets/svgs/facebook.svg';
import Verifyregistercode from "./verifyregistercode";
import { isMobile } from "react-device-detect";
import { Backbutton } from "../Components/buttons";
import axios from "axios";
import { returnErrormessage } from "../Utils/functions";



const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const location = useLocation()
    const usertype = location.state?.usertype


    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');


    const [loading, setloading] = useState(false);
    const [showPassword, setshowPassword] = useState(false);
    const [rememberme, setrememberme] = useState(false);
    const [showentercode, setshowentercode] = useState(false);


    const handleSubmit = (event) => {
        seterror(null)
        setloading(true)
        event.preventDefault();
        axiosInstance.post(Loginuserurl, {
            email: email,
            password: password
        }).then(response => {
            setloading(false)
            setsuccess('Login successful,redirecting,,,')
            if (response.data.success) {
                const datatosave = response.data.data
                dispatch(setUser({ token: datatosave.bearer_token, verification: "verified" }))
                // //fetch user details
                fetchuserdetails(datatosave.bearer_token)
            }
        }).catch(error => {
            setloading(false)
            if (error == 'Unverified') {
                setshowentercode(true)
            } else {
                seterror(error || "Unable to login.Try again later");
            }
        });

    };

    const fetchuserdetails = (token) => {
        const tempaxiosInstance = axios.create({
            timeout: 120000,
            timeoutErrorMessage: 'The server timed out while performing a request.',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token || ''}`,
            },
        });
        tempaxiosInstance.get(Fetchaccountprofileurl).then(response => {
            setsuccess('')
            if (response.data?.account_type) {
                const datatosave = response.data
                dispatch(setUser({ ...datatosave, token, verification: "verified" }))
                if (datatosave.account_type === "CLIENT") {
                    navigate("/client-onboarding-1")
                } else if (datatosave.account_type === "CANDIDATE") {
                    // navigate("/candidateaccount")
                    navigate("/candidate-onboarding-1")
                }
            }
        })
    }


    const svgsize = isMobile ? "15px" : '20px'

    return (
        <Box position={'relative'}>
            {loading && <Loadingspinner />}
            {showentercode && <Verifyregistercode useremail={email} onsuccess={() => setshowentercode(false)} ondismissmodals={() => setshowentercode(false)} />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Stack flexDirection={"row"} height={"100%"}>
                <Box display={{ lg: "block", xs: "none", sm: "none" }} sx={{ backgroundColor: '#161616', width: "60%", height: "100vh", position: "relative", overflow: "hidden" }}>
                    <div className="floatingglobemessage">
                        <Sitelogo type={'transparent'} height="40px" />
                        <Typography mt={{
                            lg: "200px",
                            sm: "100px"
                        }} fontSize={'25px'} color={"silver"}>Welcome to</Typography>
                        <Typography lineHeight={'100px'} fontSize={"35px"} fontFamily={"boldfont"} color={'white'}>
                            {usertype === 'CLIENT' ? 'Kontorva' : 'Kontorva Community'}
                        </Typography>
                        <Typography color={'silver'}>
                            {usertype === 'CLIENT' ? 'Connect with over 23 Million developers worldwide for your next big project' : 'Home to 23 Million developers worldwide'}
                        </Typography>
                        <Typography color={primarycolor}>
                            {usertype === 'CLIENT' ? 'Find Your talent now!' : 'Know more!'}
                        </Typography>
                    </div>
                    <video
                        autoPlay
                        muted
                        loop
                        style={{
                            position: "absolute",
                            opacity: 0.6,
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            zIndex: 5,
                        }}
                    >
                        <source src={require("./../../assets/videos/world.mp4")} type="video/mp4" />
                    </video>
                </Box>
                <Box width={{ lg: "40%", sm: "96%", xs: "96%" }} sx={{ backgroundColor: 'white', height: "100%", paddingLeft: "2%" }}>
                    <Container sx={{ mt: 2 }}>
                        {isMobile && <Backbutton onClick={() => navigate(-1)} />}
                        <Typography fontSize={25} fontFamily={'boldfont'}>
                            Welcome Back!<br />
                            Login to your account
                        </Typography>
                        <Typography color={"gray"} fontSize={15} mt={1}>
                            {usertype === 'CANDIDATE' ?
                                "Its nice to see you again. Ready to code?" :
                                'Its nice to see you again. Ready to hire?'
                            }
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} width={"100%"} mt={2}>
                            <Stack >
                                <Customsectiontextinput
                                    fieldname={'Email'}
                                    value={email}
                                    onChange={setemail}
                                    type={'email'}
                                    required={true}
                                    autoComplete={'email'}
                                    width={"100%"}
                                />
                                <Customsectiontextinput
                                    fieldname={'Password'}
                                    value={password}
                                    onChange={setpassword}
                                    required={true}
                                    width={"100%"}
                                    type={showPassword ? 'text' : 'password'}
                                    inputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setshowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Stack>
                            <br />
                            <Button type="submit" variant="contained" fullWidth>
                                {loading ? <CircularProgress size={20} sx={{ color: "white" }} /> : 'Log In'}
                            </Button>
                            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                                <Stack flexDirection={"row"} alignItems={"center"}>
                                    <Checkbox checked={rememberme} onChange={() => setrememberme(!rememberme)} />
                                    <Typography fontSize={{ sm: "13px", xs: "13px" }}>Remember me</Typography>&nbsp;
                                </Stack>
                                <Button sx={{ fontSize: isMobile && "12px" }} onClick={() => navigate("/resetpass", { state: { usertype: usertype } })} variant="text">Forgot password?</Button>
                            </Stack>
                            <Button color="secondary" sx={{ marginTop: 3 }} variant="outlined" fullWidth startIcon={<img src={googleIcon} style={{ height: svgsize, width: svgsize }} />}>Continue with Google</Button>
                            {usertype === 'CANDIDATE' &&
                                <Stack mt={2} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                    <Button
                                        sx={{ fontSize: isMobile && "10px" }}
                                        color="secondary"
                                        variant="outlined"
                                        startIcon={<img src={linkedinIcon} style={{ height: svgsize, width: svgsize }} />}>
                                        Linkedin
                                    </Button>
                                    <Button
                                        sx={{ fontSize: isMobile && "10px" }}
                                        color="secondary"
                                        variant="outlined"
                                        startIcon={<img src={githubIcon} style={{ height: svgsize, width: svgsize }} />}>
                                        Github
                                    </Button>
                                    <Button
                                        sx={{ fontSize: isMobile && "10px" }}
                                        color="secondary"
                                        variant="outlined"
                                        startIcon={<img src={facebookIcon} style={{ height: svgsize, width: svgsize }} />}>
                                        Facebook
                                    </Button>
                                </Stack>
                            }
                        </Box>
                    </Container>
                </Box>

            </Stack>
        </Box>
    );
};

export default Login



