import { Box, Button, Checkbox, Container, IconButton, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sitelogo from "./sitelogo";
import Popupdialog from "../Components/popupcomponent";
import Loadingspinner from "../Components/loading";
import { Erroralert, Successalert } from "../Components/popups";
import useAuthAxios from "../../hooks/useAuthAxios";
import { Resetpassurl, Sendrequestpassurl, Validatepasscodeurl } from "../Utils/urls";
import VerificationInput from "react-verification-input";
import { primarycolor } from "../Utils/colors";
import { isMobile } from "react-device-detect";
import { Backbutton } from "../Components/buttons";




const Resetpass = () => {
    const navigate = useNavigate()

    const [progress, setprogress] = useState(false);
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState("");
    const location = useLocation()
    const usertype = location.state?.usertype

    const [email, setemail] = useState('');

    const [code, setcode] = useState(0);

    const [showresetpass, setshowresetpass] = useState(true);
    const [showentercode, setshowentercode] = useState(false);
    const [showenternewpass, setshowenternewpass] = useState(false);
    const [confirmreset, setconfirmreset] = useState();

    const handleSubmit = (event) => {
        seterror(null)
        setprogress(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email')

        if (!email) {
            seterror("Enter an email to receive reset pass code")
            setprogress(false)
        } else {
            setemail(email)
            seterror(null)
            axiosInstance.post(Sendrequestpassurl, {
                email: email,
            }).then(response => {
                setprogress(false)

                setsuccess("Success.Verification code has been sent to the email provided")
                setTimeout(() => {
                    setshowresetpass(false)
                    setshowenternewpass(false)
                    setshowentercode(true)
                    setsuccess('')
                    seterror('')
                    setshowentercode(true)
                }, 5000);

            }).catch(error => {
                setprogress(false)
                seterror(error || "Sorry an error occurred,try again later");
            });
        }

    };

    //validate code
    const validatecode = (event) => {
        seterror(null)
        event.preventDefault();
        if (code.length < 6) {
            seterror('invalid code')
        } else {
            setprogress(true)
            axiosInstance.post(Validatepasscodeurl, {
                email: email,
                code: parseInt(code)
            }).then(function (response) {
                setprogress(false)
                setsuccess('Code validated successfully')
                setTimeout(() => {//navigate user after 2 seconds of display
                    setshowresetpass(false)
                    setshowenternewpass(true)
                    setshowentercode(false)
                    setsuccess('')
                    seterror('')
                }, 2000);

            }).catch(function (error) {
                setprogress(false)
                seterror(error || "there was an error try again later")
            });
        }
    }

    //reset password
    const resetpassword = (event) => {
        seterror(null)
        setprogress(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const newpassword = data.get('newpassword')
        const cnewpassword = data.get('cnewpassword');

        if (cnewpassword !== newpassword) {
            seterror('the two passwords do not match')
            setprogress(false)
        } else {
            axiosInstance.post(Resetpassurl, {
                email: email,
                password: newpassword
            }).then(function (response) {
                setprogress(false)
                setsuccess('Password changed successfully')
                setTimeout(() => {//navigate user after 2 seconds of display
                    setsuccess('')
                    seterror('')
                    navigate("/login")
                }, 2000);

            }).catch(function (error) {
                setprogress(false)
                seterror(error || "there was an error try again later")
            });
        }
    }



    return (
        <Box position={'relative'}>
            {confirmreset &&
                <Popupdialog
                    toptext={"Confirm Reset!!"}
                    question={"Are you sure you want to reset your password?"}
                    successtext={"Submit"}
                    oncancel={() => setconfirmreset(false)}
                    onsuccess={handleSubmit}
                />
            }
            {progress && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Stack flexDirection={"row"} height={"100%"}>
                <Box display={{ lg: "block", sm: "none", xs: "none" }} sx={{ backgroundColor: '#161616', width: "60%", height: "100vh", position: "relative", overflow: "hidden" }}>
                    <div className="floatingglobemessage">
                        <Sitelogo type={'dark'} height="40px" />
                        <Typography mt={{
                            lg: "200px",
                            sm: "100px"
                        }} fontSize={'25px'} color={"silver"}>Welcome to</Typography>
                        <Typography lineHeight={'100px'} fontSize={"35px"} fontFamily={"boldfont"} color={'white'}>
                            {usertype === 'CLIENT' ? 'Kontorva Hiring Hub' : 'Kontorva Community'}
                        </Typography>
                        <Typography color={'silver'}>
                            {usertype === 'CLIENT' ? 'Connect with over 23 Million developers worldwide for your next big project' : 'Home to 23 Million developers worldwide'}
                        </Typography>
                        <Typography color={primarycolor}>
                            {usertype === 'CLIENT' ? 'Find Your talent now!' : 'Know more!'}
                        </Typography>
                    </div>
                    <video
                        autoPlay
                        muted
                        loop
                        style={{
                            position: "absolute",
                            opacity: 0.6,
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            zIndex: 5,
                        }}
                    >
                        <source src={require("./../../assets/videos/world.mp4")} type="video/mp4" />
                    </video>
                </Box>
                <Box width={{ lg: "40%", sm: "100%", xs: "100%" }} sx={{ backgroundColor: 'white', height: "100%", paddingLeft: "2%" }}>
                    <Container sx={{ mt: isMobile ? 2 : 15 }}>
                        {isMobile && <Backbutton onClick={() => navigate(-1)} />}
                        {showresetpass &&
                            <div>
                                <Typography mt={1} fontSize={25} fontFamily={'boldfont'}>
                                    Forgot Password?
                                </Typography>
                                <Typography color={"gray"} fontSize={15} mt={1}>
                                    No Problem! Enter your email or username below and we will send you a code with instructions to reset your password.
                                </Typography>
                                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Get Code
                                    </Button>
                                </Box>
                            </div>}
                        {showentercode &&
                            <div>
                                <Typography mt={2} fontSize={25} fontFamily={'boldfont'}>
                                    Received a verification code?
                                </Typography>
                                <Typography color={"gray"} fontSize={15} mt={1}>
                                    Enter the code sent to {email}
                                </Typography>
                                <Box component="form" onSubmit={validatecode} sx={{ mt: 1 }}>
                                    <VerificationInput
                                        length={6}
                                        onChange={setcode}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Validate Code
                                    </Button>
                                </Box>
                            </div>}
                        {showenternewpass &&
                            <div>
                                <Typography mt={2} fontSize={25} fontFamily={'boldfont'}>
                                    Almost there!!
                                </Typography>
                                <Typography color={"gray"} fontSize={15} mt={1}>
                                    Input your preferred password on the input below
                                </Typography>
                                <Box component="form" onSubmit={resetpassword} sx={{ mt: 1 }}>
                                    <TextField margin="normal" type='password' required fullWidth id="newpassword" label="New password" name="newpassword" /><br />
                                    <TextField margin="normal" type='password' required fullWidth id="cnewpassword" label="Confirm password" name="cnewpassword" />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Change password
                                    </Button>
                                </Box>
                            </div>}

                        <Stack mt={10} flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
                            <Typography>Back To</Typography>
                            <Button onClick={() => navigate(-1)} variant="text">Login</Button>
                        </Stack>
                    </Container>
                </Box>

            </Stack>
        </Box>
    );
};

export default Resetpass



