import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import userprofilesReducer from "./userprofiles"
import newassessmentReducer from "./newassessment"

export default configureStore({
	reducer: {
		user: userReducer,
		profiles: userprofilesReducer,
		newassessment: newassessmentReducer
	},
});
