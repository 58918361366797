
import { Clear } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Container, IconButton, Modal, Snackbar, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { skilllevels } from '../Utils/arrays';

//success pop up
export const Successalert = ({ message, onClose }) => {


    return (
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open onClose={() => onClose()} autoHideDuration={3000}>
            <Alert severity="success">{message}</Alert>
        </Snackbar>
    )
}

//Error Pop up
export const Erroralert = ({ message, onClose }) => {

    return (
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open onClose={() => onClose()} autoHideDuration={3000}>
            <Alert severity="error">{message}</Alert>
        </Snackbar>
    )
}

//Success Non pop up
export const Successnonpop = ({ message }) => {

    return (
        <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {message}
        </Alert>
    )
}


//Error Non pop up
export const Errornonpop = ({ message }) => {

    return (
        <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {message} — <strong>check it out!</strong>
        </Alert>
    )
}

//skills poop up
export const Skillspopup = ({ ondismiss }) => {


    return (
        <Modal
            open={true}
            onClose={() => ondismiss()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Container maxWidth={"sm"} sx={{ backgroundColor: 'white', mt: 10, borderRadius: 4, pb: 3 }}>
                <Stack mt={5} flexDirection={"row"} justifyContent={"right"} alignItems={'flex-end'}>
                    <IconButton onClick={() => ondismiss()}><Clear /></IconButton>
                </Stack>
                <Typography fontSize={20} fontFamily={"boldfont"}>Skills Levels</Typography>
                <hr />
                <Box mt={2}>
                    {skilllevels.map((level, key) => {

                        return (
                            <Stack key={key} padding={1} width={'90%'} >
                                <Typography fontFamily={"boldfont"}>{level.label}</Typography>
                                <Typography color={'gray'} >{level.description}</Typography>
                            </Stack>
                        )
                    })}
                </Box>

            </Container>
        </Modal>
    )
}
