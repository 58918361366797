import { createSlice } from '@reduxjs/toolkit';
import { removeFromStore, setToStore } from '../pages/Utils/functions';
import { reduxconstants } from '../pages/Utils/arrays';

export const profilesSlice = createSlice({
    name: reduxconstants.USER_PROFILES,
    initialState: {
        profiles: [],
    },
    reducers: {
        setProfiles: function (state, action) {
            state.profiles = action.payload;
            setToStore(reduxconstants.USER_PROFILES, action.payload);
        },
        removeProfiles: function (state) {
            state.profiles = [];
            removeFromStore(reduxconstants.USER_PROFILES)
        },
    },
});

export const { setProfiles, removeProfiles } = profilesSlice.actions;
export default profilesSlice.reducer;
