import React from "react"
import { ArrowBack, Check, DownloadForOfflineOutlined, InsertPhotoOutlined, PictureAsPdfOutlined, Replay, Send } from "@mui/icons-material"
import { Button, IconButton, Menu, MenuItem } from "@mui/material"
import { FaAngleLeft, FaArrowAltCircleLeft, FaCheck, FaCloudUploadAlt, FaEdit, FaPlus, FaSave, FaSyncAlt, FaTimesCircle } from "react-icons/fa"
// import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state"
// import { captureScreenshot, handleMenuItemClick } from "../Utils/functions"


//auto generate/ sync button
export const Syncbutton = ({ onClick, text }) => {
    return (
        <Button onClick={() => onClick()} variant="contained" color="secondary" startIcon={<FaSyncAlt size={10} />} sx={{ height: 30 }}>{text}</Button>
    )
}

//Downloadbutton
export const Downloadbutton = ({ onClick, variant }) => {
    return (
        <Button onClick={() => onClick()} variant={variant && variant} startIcon={<DownloadForOfflineOutlined size={10} />} sx={{ height: 30 }}>Download</Button>
    )
}
//Upload button
export const Uploadbutton = ({ onClick, variant, disabled }) => {
    return (
        <Button disabled={disabled} onClick={() => onClick()} variant={variant && variant} startIcon={<FaCloudUploadAlt size={15} />} sx={{ height: 30 }}>Upload</Button>
    )
}
//done button
export const Donebutton = ({ onClick, text, disabled }) => {
    return (
        <Button onClick={() => onClick()} disabled={disabled} variant="outlined" title="save" startIcon={<Check cursor={"pointer"} />} sx={{ height: 30 }}>{text ? text : 'Done'}</Button>
    )
}
//save button
export const Savebutton = ({ onClick, text, disabled }) => {
    return (
        <Button disabled={disabled} onClick={() => onClick()} variant="outlined" title="save" startIcon={<FaSave cursor={"pointer"} />} sx={{ height: 30 }}>{text ? text : 'Save'}</Button>
    )
}

//save button auto
export const Savebuttonauto = ({ onClick, text, disabled }) => {
    return (
        <Button disabled={disabled} type="submit" variant="outlined" title="save" startIcon={<FaSave cursor={"pointer"} />} sx={{ height: 30 }}>{text ? text : 'Save'}</Button>
    )
}

//send button
export const Sendbutton = ({ onClick, disabled }) => {
    return (
        <div style={{ textAlign: "end", width: "90%" }}>
            <Button type="submit" disabled={disabled} variant="outlined" onClick={onClick} title="save" startIcon={<Send cursor={"pointer"} />} sx={{ height: 30 }}>Send</Button>
        </div>
    )
}

//Edit button
export const Editbutton = ({ onClick, text, variant, disabled }) => {
    return (
        <Button disabled={disabled} onClick={() => onClick()} variant={variant ? variant : "outlined"} title="click to edit" startIcon={<FaEdit size={10} />} sx={{ height: 30 }}>Edit</Button>
    )
}

//back button
export const Backbutton = ({ onClick, text }) => {
    return (
        <IconButton color="primary" onClick={onClick} ><ArrowBack /></IconButton>
    )
}

{/* <Button onClick={() => onClick()} variant="contained" title="click to go back" startIcon={<FaArrowAltCircleLeft size={15} />} sx={{ height: 30 }}>{text ? text : 'Back'}</Button> */}


//push item to any stack
export const Pushbutton = ({ onClick, text, color, disabled, type }) => {
    return (
        <Button disabled={disabled} type={type} onClick={() => onClick()} variant="contained" color={color && color} startIcon={<FaPlus size={10} />} sx={{ height: 30 }}>{text}</Button>
    )
}

//push item to any stack
export const Pushbuttonauto = ({ text, color, disabled, type }) => {
    return (
        <Button disabled={disabled} type={type} variant="contained" color={color && color} startIcon={<FaPlus size={10} />} sx={{ height: 30 }}>{text}</Button>
    )
}


//Cancel button
export const Cancelbutton = ({ onClick, color }) => {
    return (
        <Button onClick={() => onClick()} variant="outlined" color={color && color} startIcon={<FaTimesCircle size={20} />} sx={{ height: 30 }}>Dismiss</Button>
    )
}


//Reload button
export const Reloadbutton = ({ onClick }) => {
    return (
        <div className="reloadbutton">
            <Replay onClick={() => onClick()} sx={{ height: 30, fontSize: 25, color: "white" }} />
        </div>
    )
}

// //export button
// export const Exportbutton = ({ section, name }) => {
//     const onselect = (type) => {
//         captureScreenshot(section, `${name + new Date().getTime()}`, type)
//     }
    
//     return (
//         <PopupState variant="popover" popupId="demo-popup-menu">
//             {(popupState) => (
//                 <React.Fragment>
//                     <Button variant="outlined" sx={{ height: 40 }} {...bindTrigger(popupState)} >Export</Button>
//                     <Menu {...bindMenu(popupState)}>
//                         <MenuItem sx={{ fontSize: 12 }} onClick={() => handleMenuItemClick(popupState, () => onselect('image'))}> <InsertPhotoOutlined />&nbsp; As image</MenuItem>
//                         <MenuItem sx={{ fontSize: 12 }} onClick={() => handleMenuItemClick(popupState, () => { onselect('pdf') })}><PictureAsPdfOutlined />&nbsp; As pdf</MenuItem>
//                     </Menu>
//                 </React.Fragment>
//             )}
//         </PopupState>

//     )
// }



