import React, { useEffect, useState } from "react"
import { Box, Button, Checkbox, IconButton, Stack, Switch, Tooltip, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../../store/user";
import { boxborder, dimtextcolor } from "../../../Utils/colors";
import { useNavigate } from "react-router-dom";
import { AccountCircle, ArrowDownward, CheckCircle, CloudDownloadOutlined, Delete, ErrorOutline, FilterList, HttpsOutlined, KeyboardBackspace, NotificationsActive, PriceChange, Tune, Visibility, VisibilityOff } from "@mui/icons-material";
import { Customcheckbox, Customsectiontextinput, Customselectinput } from "../../../Components/textinputs.";
import Popupdialog from "../../../Components/popupcomponent";
import { Erroralert, Successalert } from "../../../Components/popups";
import Loadingspinner from "../../../Components/loading";
import useAuthAxios from "../../../../hooks/useAuthAxios";
import { convertTimestampToTime, displaydate, returnErrormessage } from "../../../Utils/functions";
import { Resetpassurl, Updateuserinfourl } from "../../../Utils/urls";
import { Billingsample, dummymanimage, subscriptionplans } from "../../../Utils/dummy";
import muistyles from "../../../Utils/muistyles";
import { companysizes } from "../../../Utils/arrays";
import Editprofilephoto from "./editprofilephoto";


const MyclientaccountSettings = ({ ondeleteaccount }) => {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()


    const [current, setcurrent] = useState(0);




    useEffect(() => {
        if (user) {
            const cloneduser = { ...user }
            dispatch(setUser({ ...cloneduser, currentpage: 'Account Settings' }))
        }
    }, []);



    const activebox = {
        cursor: "pointer",
        backgroundColor: 'rgb(221, 220, 220)'
    }
    const inactivebox = {
        cursor: "pointer"
    }




    return (
        <Box width={"100%"}>
            <Stack flexDirection={"row"}>
                <KeyboardBackspace onClick={() => navigate(-1)} color="primary" style={{ cursor: "pointer" }} />&nbsp;
                <Typography fontFamily={'boldfont'}>Account</Typography>
            </Stack>
            <Stack mt={1} flexDirection={"row"} width={"100%"} justifyContent={"space-between"} >
                <Box width={"30%"} height={"80vh"} position={"relative"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    <Stack onClick={() => setcurrent(0)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 0 ? activebox : inactivebox}>
                        <IconButton size="medium"><AccountCircle /></IconButton>
                        <Typography fontFamily={"boldfont"}>My Profile</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(1)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 1 ? activebox : inactivebox}>
                        <IconButton size="medium"><HttpsOutlined /></IconButton>
                        <Typography fontFamily={"boldfont"}>Security</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(2)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 2 ? activebox : inactivebox}>
                        <IconButton size="medium"><NotificationsActive /></IconButton>
                        <Typography fontFamily={"boldfont"}>Notifications</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(3)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 3 ? activebox : inactivebox}>
                        <IconButton size="medium"><Tune /></IconButton>
                        <Typography fontFamily={"boldfont"}>Preferences</Typography>
                    </Stack>
                    <Stack onClick={() => setcurrent(4)} mt={1} mb={1} flexDirection={"row"} alignItems={"center"} sx={current === 4 ? activebox : inactivebox}>
                        <IconButton size="medium"><PriceChange /></IconButton>
                        <Typography fontFamily={"boldfont"}>Billing</Typography>
                    </Stack>
                    <Stack bottom={20} onClick={() => ondeleteaccount()} position={"absolute"} flexDirection={"row"} alignItems={"center"} sx={{ cursor: "pointer" }}>
                        <IconButton color="error" size="medium"><Delete /></IconButton>
                        <Typography color={'error'} fontFamily={"boldfont"}>Delete Account</Typography>
                    </Stack>
                </Box>
                <Box width={"68%"} padding={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                    {current === 0 && <Myprofile />}
                    {current === 1 && <Passwords />}
                    {current === 2 && <Notificationsbox />}
                    {current === 3 && <Preferences />}
                    {current === 4 && <Billingbox />}
                </Box>
            </Stack >
        </Box >
    )
}


const Myprofile = () => {
    const axiosInstance = useAuthAxios()
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch()

    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);

    const [email, setemail] = useState(user?.email || '');
    const [fullname, setfullname] = useState(user?.name || '');
    const [companyname, setcompanyname] = useState(user?.companyname || '');
    const [companysize, setcompanysize] = useState(user?.companysize || '');
    const [companyemail, setcompanyemail] = useState(user?.companyemail || '');
    const [jobtitle, setjobtitle] = useState(user?.jobtitle || '');

    //booleans
    const [showeditimage, setshoweditimage] = useState(false);
    const [confirmsave, setconfirmsave] = useState(false);


    const handleSubmit = () => {
        setconfirmsave(false)
        seterror(null)
        setloading(true)
        axiosInstance.post(Updateuserinfourl, {
            email: email,
            name: fullname,
            companyname: companyname,
            companysize: companysize,
            companyemail: companyemail,
            jobtitle: jobtitle
        }).then(function (response) {
            setloading(false)
            if (response.data?.success) {
                setsuccess('Details updated successfully')
                setTimeout(() => {
                    setsuccess('')
                    dispatch(setUser({ ...user, email, name: fullname, companyname, companysize, companyemail, jobtitle }))
                }, 2000);
            }
        }).catch(function (error) {
            setloading(false)
            seterror(error || "there was an error try again later")
        });

    }


    const subscroll = {
        overflowX: "scroll",
        '::-webkit-scrollbar': {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }

    return (
        <Box>
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Details!!"}
                    question={"Are you sure you want to update your details?"}
                    successtext={"update"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            {showeditimage && <Editprofilephoto ondismiss={() => setshoweditimage(false)} />}
            <Typography mb={1}>My Profile</Typography>
            <hr />
            <Box>
                <Stack flexDirection={"row"} mt={2} alignItems={"center"}>
                    <img src={dummymanimage} className="homeprofileimage" />
                    <Box ml={2}>
                        <Stack flexDirection={"row"}>
                            <Button onClick={() => setshoweditimage(true)} sx={{ textTransform: "none" }} variant="outlined" color="secondary">Change Photo</Button>&nbsp;
                            <Button variant="outlined" sx={{ textTransform: "none" }} color="error">Remove</Button>
                        </Stack>
                        <Typography mt={1} color={dimtextcolor} fontSize={13} fontFamily={'boldfont'}>PNG, JPG or GIF (max 2mb)</Typography>
                    </Box>
                </Stack>

                <Box mt={2} onSubmit={(e) => {
                    e.preventDefault()
                    setconfirmsave(true)
                }} component={'form'} >
                    <Box sx={subscroll} height={'40vh'}>
                        <Customsectiontextinput
                            fieldname={'Name'}
                            width={"80%"}
                            value={fullname}
                            required={true}
                            onChange={setfullname}
                        />
                        <Customsectiontextinput
                            fieldname={'Email'}
                            width={"80%"}
                            type={'email'}
                            required={true}
                            autoComplete={'email'}
                            value={email}
                            onChange={setemail}
                        />
                        <Typography mt={2} fontSize={13}>Company Details</Typography>
                        <Box mt={1}>
                            <Customsectiontextinput
                                fieldname={'Company Name'}
                                width={"80%"}
                                autoComplete={"organization"}
                                required={true}
                                type={'text'}
                                value={companyname}
                                onChange={setcompanyname}
                            />
                            <Customsectiontextinput
                                fieldname={'Job Title'}
                                width={"80%"}
                                autoComplete={"organization-title"}
                                required={true}
                                type={'text'}
                                value={jobtitle}
                                onChange={setjobtitle}
                            />
                            <Customsectiontextinput
                                fieldname={'Company Email'}
                                width={"80%"}
                                required={true}
                                type={'email'}
                                autoComplete={'email'}
                                value={companyemail}
                                onChange={setcompanyemail}
                            />
                            <Customselectinput
                                fieldname={'Company Size'}
                                width={"80%"}
                                options={companysizes}
                                required={true}
                                value={companysize}
                                onChange={setcompanysize}
                            />
                        </Box><br />
                    </Box>
                    <Button type="submit" variant="contained">Save</Button>
                </Box>

            </Box>
        </Box>
    )
}


const Passwords = () => {
    const axiosInstance = useAuthAxios()
    const [error, seterror] = useState('');
    const [success, setsuccess] = useState('');
    const [loading, setloading] = useState(false);

    const [oldpass, setoldpass] = useState('');
    const [newpass, setnewpass] = useState('');
    const [confirmpass, setconfirmpass] = useState('');

    const [showpasswords, setshowpasswords] = useState(false);

    //modals
    const [confirmsave, setconfirmsave] = useState(false);

    const handleSubmit = () => {
        setconfirmsave(false)
        if (confirmpass !== newpass) {
            seterror('the two passwords do not match')
        }
        setloading(true)
        axiosInstance.post(Resetpassurl, {
            currentpassword: oldpass,
            password: newpass
        }).then(function (response) {
            setloading(false)
            if (response.data.success) {
                setsuccess('Password changed successfully')
                setTimeout(() => {//navigate user after 2 seconds of display
                    setsuccess('')
                    seterror('')
                    setoldpass('')
                    setnewpass('')
                    setconfirmpass('')
                }, 2000);
            }
        }).catch(function (error) {
            setloading(false)
            seterror(returnErrormessage(error))
        });

    }

    // Regex for password validation
    const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

    // Check if new password meets the rules
    const isValidNewPass = passwordRules.test(newpass);
    const isValidConfirmPass = passwordRules.test(confirmpass);



    return (
        <Box>
            {confirmsave &&
                <Popupdialog
                    toptext={"Confirm Details!!"}
                    question={"Are you sure you want to change your password?"}
                    successtext={"Change"}
                    oncancel={() => setconfirmsave(false)}
                    onsuccess={handleSubmit}
                />
            }
            {loading && <Loadingspinner />}
            {error?.length > 0 && <Erroralert message={error} onClose={() => seterror()} />}
            {success?.length > 0 && <Successalert onClose={() => setsuccess()} message={success} />}
            <Typography mb={1}>Change Password</Typography>
            <hr />
            <Box mt={2} component={'form'} onSubmit={(e) => {
                e.preventDefault()
                setconfirmsave(true)
            }}>
                <Customsectiontextinput
                    fieldname={'Current Password'}
                    value={oldpass}
                    onChange={setoldpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />
                <Customsectiontextinput
                    fieldname={'New Password'}
                    value={newpass}
                    onChange={setnewpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                    helperText={!isValidNewPass && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                /><br />
                <Customsectiontextinput
                    fieldname={'Confirm Password'}
                    value={confirmpass}
                    onChange={setconfirmpass}
                    required={true}
                    width={"100%"}
                    type={showpasswords ? 'text' : 'password'}
                    inputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setshowpasswords(!showpasswords)}
                                edge="end"
                            >
                                {!showpasswords ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                    helperText={!isValidConfirmPass && newpass && "Password must be 8+ characters, include upper/lowercase, a number, and a special character."}
                />
                <Stack flexDirection={"row"} justifyContent={"right"}>
                    <Button type="submit" disabled={!oldpass || !isValidConfirmPass || !isValidNewPass} variant="contained">Save</Button>
                </Stack>
            </Box>
        </Box>
    )
}


const Notificationsbox = () => {

    const [newmatchedroles, setnewmatchedroles] = useState(false);
    const [retakerequest, setretakerequest] = useState(true);
    const [interviewrequest, setinterviewrequest] = useState(false);
    const [interviewrejected, setinterviewrejected] = useState(true);

    return (
        <Box>
            <Typography mb={1}>Notifications</Typography>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>Manage when you'll receive Notifications</Typography>
            <Box mt={2}>
                <Stack flexDirection={"row"}>
                    <Customcheckbox value={newmatchedroles} onChange={() => setnewmatchedroles(!newmatchedroles)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>New Matched Roles</Typography>
                        <Typography>A new role matches with my profile and skills</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={retakerequest} onChange={() => setretakerequest(!retakerequest)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Test retake request</Typography>
                        <Typography>A recruiter requests a retake of a previously done test</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={interviewrequest} onChange={() => setinterviewrequest(!interviewrequest)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Interview request received</Typography>
                        <Typography>A recruiter requests an interview with me for a role</Typography>
                    </Box>
                </Stack>
                <Stack mt={2} flexDirection={"row"}>
                    <Customcheckbox value={interviewrejected} onChange={() => setinterviewrejected(!interviewrejected)} />
                    <Box ml={1}>
                        <Typography fontFamily={'boldfont'}>Interview request rejected</Typography>
                        <Typography>A recruiter rejects my request to be considered for a role</Typography>
                    </Box>
                </Stack>
            </Box>
        </Box>
    )
}


const Preferences = () => {
    const [preferences, setPreferences] = useState({
        viewPreference: true,
        emailPublic: true,
        dobPublic: true,
        phonePublic: true,
    });

    const handleToggle = (key) => {
        setPreferences((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    return (
        <Box>
            <Typography mb={1}>Platform Preferences</Typography>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>
                Manage the Platform Preferences
            </Typography>
            <Box mt={2}>
                {[
                    { label: 'View Preference', description: 'Toggle On for list View', key: 'viewPreference' },
                    { label: 'Make Email Address Public', description: 'email address public for users to view', key: 'emailPublic' },
                    { label: 'Make Date Of Birth Public', description: 'date of birth public for users to view', key: 'dobPublic' },
                    { label: 'Make Phone Number Public', description: 'phone public for users to view', key: 'phonePublic' },
                ].map(({ label, description, key }, index) => (
                    <Stack key={index} mt={3} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Box ml={1}>
                            <Typography fontSize={16}>{label}</Typography>
                            <Typography color={dimtextcolor} fontSize={13}>{description}</Typography>
                        </Box>
                        <Switch
                            checked={preferences[key]}
                            onChange={() => handleToggle(key)}
                        />
                    </Stack>
                ))}
            </Box>
        </Box>
    );
};



const Billingbox = () => {

    const [templan, settemplan] = useState('');
    const [currentplan, setcurrentplan] = useState(subscriptionplans[1]);

    //arrays
    const [displayedbilling, setdisplayedbilling] = useState(Billingsample || []);
    const [selectedbilling, setselectedbilling] = useState([]);

    //booleans
    const [confirmplanchange, setconfirmplanchange] = useState(false);


    const subscroll = {
        overflowX: "scroll",
        '::-webkit-scrollbar': {
            display: 'none'
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }

    const handlePlanchange = () => {
        setconfirmplanchange(false)
        setcurrentplan(templan)
    }


    //select all
    const onselectall = () => {
        if (selectedbilling.length === displayedbilling.length) {
            setselectedbilling([])
        } else {
            setselectedbilling(displayedbilling)
        }
    }

    //select or unselect billing
    const checkbilling = (isselected, billing, billingkey) => {
        if (isselected) {
            setselectedbilling(selectedbilling.filter((obj, key) => key !== billingkey))
        } else {
            setselectedbilling((prev) => [...prev, billing])
        }
    }
    return (
        <Box>
            {confirmplanchange &&
                <Popupdialog
                    toptext={"Confirm Plan Change!!"}
                    question={"Are you sure you want to change your plan?"}
                    successtext={"Submit"}
                    oncancel={() => setconfirmplanchange(false)}
                    onsuccess={handlePlanchange}
                />
            }
            <Typography mb={1}>Plans & Billing</Typography>
            <hr />
            <Typography mt={1} fontSize={14} fontFamily={'boldfont'}>Manage your plans and subscriptions</Typography>
            <Box mt={2}>
                <Stack flexDirection={"row"} sx={subscroll}>
                    {subscriptionplans.map((subscription, key) => {
                        const iscurrent = currentplan.plan_name === subscription.plan_name

                        return (
                            <Box onClick={() => {
                                settemplan(subscription)
                                setconfirmplanchange(true)
                            }} position={"relative"} sx={{ border: iscurrent ? '2px solid blue' : boxborder, cursor: "pointer" }} key={key} borderRadius={3} padding={2} mr={2}>
                                {iscurrent && <Box sx={{ position: "absolute", right: 10, top: 4 }}><CheckCircle fontSize="small" color="primary" /></Box>}
                                <Typography color={'primary'} fontFamily={"boldfont"} fontSize={14}>{subscription.plan_name}</Typography>
                                <Typography fontFamily={"boldfont"} fontSize={24}>{subscription.price}</Typography>
                                <Stack>
                                    {subscription.features.map((feature, key) => {

                                        return (
                                            <Stack mb={1} flexDirection={"row"} key={key}>
                                                <CheckCircle fontSize="small" color="primary" />
                                                <Tooltip title={feature} placement="top">
                                                    <Typography
                                                        sx={{
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            maxWidth: '200px' // Adjust width as needed
                                                        }}
                                                        whiteSpace={"nowrap"} ml={1} fontSize={13}>{feature}</Typography>
                                                </Tooltip>
                                            </Stack>
                                        )
                                    })}
                                </Stack>
                            </Box>
                        )
                    })}

                </Stack>
                <Box mt={2}>
                    <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography>Billing History</Typography>
                        <Stack flexDirection={"row"}>
                            <Button sx={{ textTransform: 'none' }} variant="outlined" startIcon={<FilterList />}>FIlter</Button>&nbsp;
                            <Button sx={{ textTransform: 'none' }} variant="outlined" startIcon={<CloudDownloadOutlined />}>Download All</Button>
                        </Stack>
                    </Stack>
                    <Box mt={2} height={"50vh"} overflow={"scroll"}>
                        <Stack sx={muistyles.stackstyle} flexDirection={"row"}>
                            <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                                <Customcheckbox onChange={() => onselectall()} size={'small'} value={displayedbilling.length === selectedbilling.length} />
                                <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>Invoice</Typography>
                                <ArrowDownward fontSize="small" />
                            </Stack>
                            <Typography sx={muistyles.headtitle}>Amount</Typography>
                            <Typography sx={muistyles.headtitle} >Date</Typography>
                            <Typography sx={muistyles.headtitle} >Status</Typography>
                            <Typography sx={{ ...muistyles.headtitle, width: "10%" }} />
                        </Stack>
                        <Box mt={2} sx={{ backgroundColor: 'white', border: boxborder, borderRadius: 2 }}>
                            {displayedbilling.map((billing, key) => {

                                const isselected = selectedbilling.find((obj) => obj.id === billing.id)

                                return (
                                    <Stack sx={muistyles.contentstackstyle} flexDirection={"row"} key={key}>
                                        <Stack flexDirection={"row"} alignItems={"center"} width={"55%"}>
                                            <Customcheckbox onChange={() => checkbilling(isselected, billing, key)} size={'small'} value={isselected} />
                                            <Typography ml={1} mr={1} fontFamily={'boldfont'} fontSize={13}>{billing.invoice}</Typography>
                                        </Stack>
                                        <Typography sx={muistyles.contentheadtitle}>{billing.amount}</Typography>
                                        <Typography sx={muistyles.contentheadtitle} >{displaydate(billing.created_at)}</Typography>
                                        <Typography sx={muistyles.contentheadtitle} >
                                            {billing.status === 'paid' ?
                                                <Stack flexDirection={"row"}><CheckCircle fontSize="small" color="success" /> Paid</Stack> :
                                                <Stack flexDirection={"row"}><ErrorOutline fontSize="small" color="error" /> unpaid</Stack>
                                            }
                                        </Typography>
                                        <Typography sx={{ ...muistyles.contentheadtitle, width: "10%" }} ><CloudDownloadOutlined /></Typography>
                                    </Stack>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default MyclientaccountSettings